<form *ngIf="form" [formGroup]="form">
    <nuc-form-field label="SVG library name">
        <nuc-input formControlName="name"
                   placeholder="SVG library name">
        </nuc-input>
        <nuc-error *ngIf="form.touched && form.controls.name.errors?.required">{{validationMessages.name.required}}</nuc-error>
    </nuc-form-field>

    <nuc-form-field label="Library engine type">
        <nuc-dropdown
            formControlName="engineType"
            placeholder="Select a library engine type"
            [nullOption]="false"
            [items]="engineTypes"
            [required]="true">
        </nuc-dropdown>
    </nuc-form-field>

    <rl-upload-file-component
        title="Library file"
        (onUploadUpdate)="uploadUpdateSubject.next($event)"
        [fileTypeCategories]="fileTypeCategories">
    </rl-upload-file-component>
</form>
