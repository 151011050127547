import {Component} from '@angular/core';
import {PaginatorService} from '../../../../../components/paginator/paginator.service';
import {EColumnSize, FullModalService, IReorderEvent, NucDialogService} from '@relayter/rubber-duck';
import {RLDatePipe} from '../../../../../pipes/rl-date.pipe';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Toaster} from '../../../../../classes/toaster.class';
import {AppConstants} from '../../../../../app.constants';
import {FormatRulesetBaseItem} from '../../../models/api/format-ruleset-item.model';
import {FormatRulesetAssetItemModel} from '../../../models/api/format-ruleset-asset-item.model';
import {FormatRulesetItemTableComponent} from '../format-ruleset-item-table.component';
import {UserSettingsStorageService} from '../../../../../api/services/user-settings-storage.service';
import {FormatRulesetService} from '../../../../../api/services/format-ruleset.service';
import {DesignLibraryService} from '../../../../../api/services/design-library.service';
import {PropertyService} from '../../../../../api/services/property.service';
import {UserIsAllowedToPipe} from '../../../../../pipes/user-is-allowed-to.pipe';
import {ActivatedRoute, Router} from '@angular/router';
import {ARLogger} from '@relayter/core';

@Component({
    selector: 'format-ruleset-asset-item-table',
    templateUrl: '../format-ruleset-item-table.component.html',
    styleUrls: ['../format-ruleset-item-table.component.scss'],
    providers: [PaginatorService]
})
export class FormatRulesetAssetItemTableComponent extends FormatRulesetItemTableComponent {
    public tableId: string = 'ruleset-item-group-table';

    constructor(userSettingsStorageService: UserSettingsStorageService,
                protected formatRulesetService: FormatRulesetService,
                protected indesignLibraryService: DesignLibraryService,
                protected propertyService: PropertyService,
                protected userIsAllowedToPipe: UserIsAllowedToPipe,
                protected route: ActivatedRoute,
                protected router: Router,
                protected dialogService: NucDialogService,
                protected fullModalService: FullModalService,
                protected paginatorService: PaginatorService) {
        super(userSettingsStorageService,
            formatRulesetService,
            indesignLibraryService,
            propertyService,
            userIsAllowedToPipe,
            route,
            router,
            dialogService,
            fullModalService,
            paginatorService);
    }

    public setupTableColumns(): void {
        this.columns.push(...[
            {
                title: 'Name',
                key: 'name',
                size: EColumnSize.BASE,
                sortProperty: 'name'
            },
            {
                title: 'Layer',
                key: 'layer',
                sortProperty: 'layer'
            },
            {
                title: 'X',
                key: 'x',
                sortProperty: 'x'
            },
            {
                title: 'Y',
                key: 'y',
                sortProperty: 'y'
            },
            {
                title: 'Width',
                key: 'width',
                sortProperty: 'width'
            },
            {
                title: 'Height',
                key: 'height',
                sortProperty: 'height'
            },
            {
                title: 'Display format',
                key: 'format.displayFormat',
                sortProperty: 'format.displayFormat'
            },
            {
                title: 'Format horizontal margin',
                key: 'format.horizontalMargin',
                sortProperty: 'format.horizontalMargin'
            },
            {
                title: 'Format vertical margin',
                key: 'format.verticalMargin',
                sortProperty: 'format.verticalMargin'
            },
            {
                title: 'Limit',
                key: 'limit',
                sortProperty: 'limit'
            },
            {
                title: 'Skip',
                key: 'skip',
                sortProperty: 'skip'
            },
            {
                title: 'Date created',
                key: 'createdAt',
                sortProperty: 'createdAt',
                format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
            },
            {
                title: 'Date modified',
                key: 'updatedAt',
                sortProperty: 'updatedAt',
                format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
            }
        ]);
    }

    protected getRulesetItems(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_FORMAT_RULESET_ASSET_ITEMS)) {
            return;
        }

        if (this.subscription) this.subscription.unsubscribe();

        this.subscription =
            this.formatRulesetService.getFormatRulesetAssetItems(
                this.ruleSetId,
                this.pageSize,
                (this.pageIndex - 1) * this.pageSize,
                this.sortProperty,
                this.sortOrder,
                this.search
            ).pipe(takeUntil(this.onDestroySubject)).subscribe(
                (result) => {
                    this.items = result.items;
                    this.total = result.total;
                    this.disableNextPage = this.pageSize * this.pageIndex >= this.total;
                },
                (error) => Toaster.handleApiError(error)
            );
    }

    protected getFormatRulesetItem(item) {
        return this.formatRulesetService.getFormatRulesetAssetItem(this.ruleSetId, item._id);
    }

    protected deleteRule(rule: FormatRulesetBaseItem): void {
        this.formatRulesetService.deleteFormatRulesetAssetItem(this.ruleSetId, rule._id)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => {
                    Toaster.success('Successfully deleted rule');
                    this.getRulesetItems();
                },
                Toaster.handleApiError);
    }

    protected setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(this.permissions.GET_FORMAT_RULESET_ASSET_ITEM)) {
            if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PUT_FORMAT_RULESET_ASSET_ITEM)) {
                this.actions = [AppConstants.TABLE_ACTION_TYPES.EDIT];
            }
            if (this.userIsAllowedToPipe.transform([AppConstants.PERMISSIONS.COPY_FORMAT_RULESET_ASSET_ITEM,
                AppConstants.PERMISSIONS.POST_FORMAT_RULESET_ASSET_ITEM])) {
                this.actions = this.actions?.concat(AppConstants.TABLE_ACTION_TYPES.COPY) || [AppConstants.TABLE_ACTION_TYPES.COPY];
            }
            if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_FORMAT_RULESET_ASSET_ITEM)) {
                this.actions = this.actions?.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) || [AppConstants.TABLE_ACTION_TYPES.DELETE];
            }
        }
    }

    protected copyRule(rule: FormatRulesetAssetItemModel): void {
        this.formatRulesetService.getFormatRuleset(this.ruleSetId).pipe(
            switchMap((ruleSet) => {
                rule._id = undefined;
                rule.name = FormatRulesetItemTableComponent.generateNewRuleName(rule.name, ruleSet.assetItems.map((item) => item.name));
                return this.formatRulesetService.postFormatRulesetAssetItem(this.ruleSetId, rule);
            }),
            takeUntil(this.onDestroySubject)
        ).subscribe(() => {
            Toaster.success('Successfully duplicate asset rule');
            this.getRulesetItems();
        }, Toaster.handleApiError);
    }

    public startOrderRules() {
        ARLogger.info('ASSET ITEMS, reset sort but keep filters');
    }

    public orderChanged(event: IReorderEvent) {
        ARLogger.info('Items ordered', event.sourceId);
    }
}
