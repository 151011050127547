<div class="header-row">
    <h1>Video template overview</h1>
    <nuc-button-bar>
        @if (permissions.POST_TEMPLATE | userIsAllowedTo) {
            <nuc-button-primary text="Add video template"
                                (click)="openVideoTemplateModal()"/>
        }
    </nuc-button-bar>
</div>

@if (permissions.GET_TEMPLATES | userIsAllowedTo) {
    <nuc-table [items]="items"
               [selectionMode]="ESelectionMode.EXPAND"
               [columns]="columns"
               [emptyStateTitle]="'This section is empty. Add the first item.'"
               [tableId]="tableId"
               [actions]="actions"
               (actionClicked)="onActionClicked($event)"
               [loading]="!dataSubscription?.closed"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)">
        <nuc-input button-bar-left
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="onSearchBarValueUpdated($event)"
                   placeholder="Search templates by name">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!dataSubscription?.closed">
        </paginator>
    </nuc-table>
} @else {
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission_small.svg"
                               small="true"
                               title="You or your team does not have permissions to view this section. Contact the team owner to enable this section.">
    </nuc-empty-state-component>
}
