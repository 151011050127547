import {autoserialize, autoserializeAs} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {DesignLibraryItemModel} from './design-library-item.model';
import {UserModel} from '../../../../models/api/user.model';
import {EEngineType} from '../../../../models/api/template.model';

export class DesignLibraryFiles {
    @autoserialize public readonly library: string;
}

export class DesignLibraryModel implements IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public engineType: EEngineType;
    @autoserializeAs(DesignLibraryFiles) public readonly files: DesignLibraryFiles;
    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public updatedAt: Date;
    @autoserializeAs(UserModel) public createdBy: UserModel;
    @autoserializeAs(DesignLibraryItemModel) public readonly items: DesignLibraryItemModel[];

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}

export class DesignLibraryPatchModel {
    public name: string;
}
