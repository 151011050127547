import {autoserialize, autoserializeAs} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class IndesignLibraryItemFiles {
    @autoserialize public readonly preview: string;
}

export class DesignLibraryItemModel implements IDropdownItem {
    @autoserialize public readonly name: string;
    @autoserialize public readonly tags: string[];
    @autoserializeAs(IndesignLibraryItemFiles) public readonly files: IndesignLibraryItemFiles;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this.name;
    }
}
