import {Component, inject, OnInit} from '@angular/core';
import {EColumnSize, EColumnType, ESelectionMode, ISortOptionEvent, ITableAction, ITableColumn, NUCTableModule} from '@relayter/rubber-duck';
import {PipesModule} from '../../../../../pipes/pipes.module';
import {RLTableComponent} from '../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../api/services/user-settings-storage.service';
import {AfterEffectsProjectFileCompositionLayerModel, EAfterEffectsLayerTypes} from '../../../../../models/api/after-effects-project-file.model';
import {Toaster} from '../../../../../classes/toaster.class';
import {ComponentsModule} from '../../../../../components/components.module';
import {Subscription} from 'rxjs';
import {PaginatorService} from '../../../../../components/paginator/paginator.service';
import {AppConstants} from '../../../../../app.constants';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute, Router} from '@angular/router';
import {AfterEffectsProjectFilesApiService} from '../../../../../api/services/after-effects-project-files.api.service';
import {MatrixUrlParams} from '../../../../../models/ui/matrix-url-params.model';

@Component({
    selector: 'after-effects-project-files-composition-layers-component',
    templateUrl: 'after-effects-project-files-composition-layers.component.html',
    styleUrls: ['after-effects-project-files-composition-layers.component.scss'],
    standalone: true,
    imports: [
        PipesModule,
        ComponentsModule,
        NUCTableModule,
    ],
    providers: [PaginatorService]
})
export class AfterEffectsProjectFilesCompositionLayersComponent extends RLTableComponent implements OnInit  {
    public tableId = 'after-effects-project-file-layers-table';
    private afterEffectsProjectFileId: string;
    private afterEffectsProjectFileCompositionId: string;

    private afterEffectsProjectFilesApiService = inject(AfterEffectsProjectFilesApiService)
    private paginatorService = inject(PaginatorService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);

    public total: number;
    public pageIndex: number;
    public pageSize: number;
    public disableNextPage = true;

    protected readonly ESelectionMode = ESelectionMode;
    public layersDataSubscription: Subscription;

    public actions: ITableAction[] = [];

    public layers: AfterEffectsProjectFileCompositionLayerModel[];

    public columns: ITableColumn[] = [
        {
            title: '',
            selectionColumnTitle: 'Thumbnail',
            key: 'thumbnailType',
            size: EColumnSize.SMALL,
            type: EColumnType.THUMBNAIL
        },
        {
            title: 'Layer name',
            key: 'name',
            sortProperty: 'name',
            type: EColumnType.DEFAULT
        },
        {
            title: 'Type',
            key: 'type',
            sortProperty: 'type',
            type: EColumnType.DEFAULT,
            format: (type) => EAfterEffectsLayerTypes[type]?.name
        }
    ];

    constructor(userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;
                if (this.afterEffectsProjectFileCompositionId) {
                    this.updateUrl();
                    this.getLayers();
                }
            });

        this.initFromRoute();
    }

    private initFromRoute(): void {
        // Get After Effects project file id from parent route
        this.afterEffectsProjectFileId = this.route.parent.snapshot.params['afterEffectsProjectFileId'];

        // Get index/sort options from route
        const params = this.route.snapshot.params;
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.tableSortOptions.fromRoute(params, this.columns);

        // Listen to composition id updates on the route
        this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
            if (this.afterEffectsProjectFileCompositionId !== params['itemId']) {
                this.afterEffectsProjectFileCompositionId = params['itemId'];
                this.paginatorService.setPageIndex(this.tableId, pageIndex);
            }
        });
    }

    private updateUrl(): void {
        const matrixUrl = new MatrixUrlParams(this.pageIndex, null,
            this.tableSortOptions.sortPropertiesAsString, this.tableSortOptions.sortOrder);

        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATES,
            AppConstants.CONTEXT_URL.AFTER_EFFECTS_PROJECT_FILES,
            this.afterEffectsProjectFileId,
            AppConstants.CONTEXT_URL.LAYERS,
            matrixUrl
        ], {replaceUrl: true, queryParams: {itemId: this.afterEffectsProjectFileCompositionId}});
    }

    public getLayers(): void {
        if (this.layersDataSubscription) this.layersDataSubscription.unsubscribe();

        const offset = (this.pageIndex - 1) * this.pageSize;

        this.layersDataSubscription =
            this.afterEffectsProjectFilesApiService.getAfterEffectsProjectFileCompositionLayers(
                this.afterEffectsProjectFileId,
                this.afterEffectsProjectFileCompositionId,
                this.pageSize,
                offset,
                this.tableSortOptions
            )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.layers = result.items;
                    this.disableNextPage = !result.hasNext;
                },
                error: Toaster.handleApiError
            });
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);
        this.setPageIndex();
    }
}
