import {autoserialize, autoserializeAs} from 'cerialize';
import {ITableItem} from '@relayter/rubber-duck';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';

export enum ECustomWorkflowTransitionRecipeTaskName {
    CREATE_POS_ITEMS = 'CREATE_POS_ITEMS',
    CREATE_WEB_ITEMS = 'CREATE_WEB_ITEMS',
    CREATE_PRINT_ITEMS = 'CREATE_PRINT_ITEMS',
    EXECUTE_AUTOMATIONS = 'EXECUTE_AUTOMATIONS',
    GENERATE_POS_ITEMS = 'GENERATE_POS_ITEMS',
    GENERATE_PRINT_ITEMS = 'GENERATE_PRINT_ITEMS',
    GENERATE_WEB_ITEMS = 'GENERATE_WEB_ITEMS',
    MOVE_UPLOADS = 'MOVE_UPLOADS',
    UPLOAD_ITEM_FILES = 'UPLOAD_ITEM_FILES',
    REASSIGNING_ITEMS = 'REASSIGNING_ITEMS',
    UPDATE_INDESIGN_CONTENT = 'UPDATE_INDESIGN_CONTENT',
    SEND_NOTIFICATIONS = 'SEND_NOTIFICATIONS',
    CREATE_PACKAGE_ITEMS = 'CREATE_PACKAGE_ITEMS',
    REFRESH_CAMPAIGN_PACKAGES = 'REFRESH_CAMPAIGN_PACKAGES',
    REJECT_RULES = 'REJECT_RULES',
    REJECT_SIGN_OFF_ROLES = 'REJECT_SIGN_OFF_ROLES',
    MOVE_ORPHANED_STICKY_NOTES = 'MOVE_ORPHANED_STICKY_NOTES',
    SAVE_LAYOUT_REVISION = 'SAVE_LAYOUT_REVISION',
    REMOVE_APPROVALS = 'REMOVE_APPROVALS',
    SAVE_FILES_REVISION = 'SAVE_FILES_REVISION',
    DELETE_PUBLICATION_ITEMS = 'DELETE_PUBLICATION_ITEMS',
    REMOVE_FILES = 'REMOVE_FILES',
    UPLOAD_ASSETS = 'UPLOAD_ASSETS',
    DELETE_ORPHANED_NOTES = 'DELETE_ORPHANED_NOTES',
    CREATE_LINKS = 'CREATE_LINKS',
    SEND_LINKS = 'SEND_LINKS'
}

export const CUSTOM_WORKFLOW_DESTRUCTIVE_RECIPE_TASK_NAMES: Record<string, (itemsTxt: string, pubItems: string) => string> =
    {
        [ECustomWorkflowTransitionRecipeTaskName.DELETE_PUBLICATION_ITEMS]: (itemsTxt: string, pubItems: string): string =>
            `Are you sure you want to delete the publication ${itemsTxt} ${pubItems}?
                All data and files of the ${itemsTxt} will be permanently removed`,
        [ECustomWorkflowTransitionRecipeTaskName.REMOVE_FILES]: (itemsTxt: string, pubItems: string): string =>
            `Are you sure you want to remove files and revisions for the publication ${itemsTxt} ${pubItems}?
                All files and revisions (for configured variants) of the ${itemsTxt} will be permanently removed`
    };

// eslint-disable-next-line max-len
export const RECIPE_TASK_PUBLICATION_ITEM_LINK = '/campaigns/{campaignId}/publications/{publicationId}/items/{publicationItemId}/uploads?variantId={variantId}';

export enum ERecipeTaskNotificationType {
    CREATE_LINKS = 'CREATE_LINKS',
    BETWEEN_STEPS_TRANSITION = 'BETWEEN_STEPS_TRANSITION'
}

export enum ERecipeTaskCollection {
    STICKY_NOTE = 'RLStickyNote',
    PUBLICATION_ITEM = 'RLPublicationItem'
}

export enum ERecipeTaskType {
    PER_VARIANT,
    REJECT_RULE,
    NOTIFICATION
}
export class RecipeTaskConfig {
    public task: ECustomWorkflowTransitionRecipeTaskName;
    public description: string;
    public progressTitle: string;
    public publicationTypes?: EPublicationType[];
    public recipeTaskType?: ERecipeTaskType;
    // when allowedTasksBefore is null, any task can be before the current one
    // when allowedTasksBefore is [], the current task has to be the first one
    // allowedTasksBefore only checks the closest task before the current one
    public allowedTasksBefore?: ECustomWorkflowTransitionRecipeTaskName[];
    public onlyTask?: boolean;
    public excludeTasks?: ECustomWorkflowTransitionRecipeTaskName[];
}

export const CUSTOM_WORKFLOW_TRANSITION_RECIPE_TASKS: RecipeTaskConfig[] =
    [
        {
            task: ECustomWorkflowTransitionRecipeTaskName.CREATE_POS_ITEMS,
            description: 'Create publication items.',
            progressTitle: 'POS items created',
            allowedTasksBefore: [ECustomWorkflowTransitionRecipeTaskName.EXECUTE_AUTOMATIONS],
            publicationTypes: [EPublicationType.POS]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.CREATE_WEB_ITEMS,
            description: 'Create publication items.',
            progressTitle: 'Web items created',
            allowedTasksBefore: [ECustomWorkflowTransitionRecipeTaskName.EXECUTE_AUTOMATIONS],
            publicationTypes: [EPublicationType.WEB]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.CREATE_PRINT_ITEMS,
            description: 'Create publication items.',
            progressTitle: 'Print items created',
            allowedTasksBefore: [],
            publicationTypes: [EPublicationType.PRINT_MAGAZINE]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.GENERATE_POS_ITEMS,
            description: 'Generate publication items.',
            progressTitle: 'POS items generated',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            publicationTypes: [EPublicationType.POS]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.GENERATE_PRINT_ITEMS,
            description: 'Generate publication items.',
            progressTitle: 'Print items generated',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            publicationTypes: [EPublicationType.PRINT_MAGAZINE]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.GENERATE_WEB_ITEMS,
            description: 'Generate publication items.',
            progressTitle: 'Web items generated',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            publicationTypes: [EPublicationType.WEB]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.EXECUTE_AUTOMATIONS,
            description: 'Run automations to create publication items.',
            progressTitle: 'Workflow automations ran',
            allowedTasksBefore: [],
            publicationTypes: [EPublicationType.POS, EPublicationType.WEB]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.MOVE_UPLOADS,
            description: 'Upload files (source and export file) for an existing publication item.',
            progressTitle: 'Uploads processed'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.UPLOAD_ITEM_FILES,
            description: 'Upload files (source, export file and attachments) for an existing publication item.',
            progressTitle: 'Uploads processed'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.REASSIGNING_ITEMS,
            description: 'Reassign campaign items to a different publication item.',
            progressTitle: 'Items reassigned'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.UPDATE_INDESIGN_CONTENT,
            description: 'Update text layer content in the InDesign file of a publication item.',
            progressTitle: 'InDesign content updated'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.SEND_NOTIFICATIONS,
            description: 'Send transition notifications or publication item upload links to configured receivers.',
            progressTitle: 'Items processed for notifications',
            recipeTaskType: ERecipeTaskType.NOTIFICATION
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.CREATE_PACKAGE_ITEMS,
            description: 'Create package items for publication items.',
            progressTitle: 'Package items created'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.REFRESH_CAMPAIGN_PACKAGES,
            description: 'Refresh all packages in the campaign.',
            progressTitle: 'Refresh packages tasks done'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.REJECT_RULES,
            description: 'Define rules that will reject the current transition.',
            progressTitle: 'Items checked for rejection',
            recipeTaskType: ERecipeTaskType.REJECT_RULE
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.REJECT_SIGN_OFF_ROLES,
            description: 'Define sign off rules that will reject the current transition.',
            progressTitle: 'Items checked for sign off rejection',
            recipeTaskType: ERecipeTaskType.REJECT_RULE
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.MOVE_ORPHANED_STICKY_NOTES,
            description: 'Check all notes of all publication items in the current transition ' +
                'and move notes among these publication items if necessary ' +
                'by checking the note location and the publication item content.',
            progressTitle: 'Items checked for notes location'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.SAVE_LAYOUT_REVISION,
            description: 'Save a new layout revision if it is changed or when there are no revisions yet.',
            progressTitle: 'Layout revision saved'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.REMOVE_APPROVALS,
            description: 'Remove all approvals from a publication item.',
            progressTitle: 'Approvals removed'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.SAVE_FILES_REVISION,
            description: 'Save a new files revision if any file (source, preview etc) ' +
                'in the publication item is changed compared to the previous revision or when there are no revisions yet.',
            progressTitle: 'Files revision saved'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.DELETE_PUBLICATION_ITEMS,
            description: 'Delete publication items and all their files.',
            progressTitle: 'Publication items deleted',
            onlyTask: true
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.REMOVE_FILES,
            description: 'Remove files from publication items. If no variants specified in the parameters, remove all the files from all variants.',
            progressTitle: 'Item files removed',
            recipeTaskType: ERecipeTaskType.PER_VARIANT
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.UPLOAD_ASSETS,
            description: 'Create a new publication item from an uploaded image or video.',
            progressTitle: 'Assets uploaded',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            excludeTasks: [
                ECustomWorkflowTransitionRecipeTaskName.CREATE_POS_ITEMS,
                ECustomWorkflowTransitionRecipeTaskName.CREATE_PRINT_ITEMS,
                ECustomWorkflowTransitionRecipeTaskName.CREATE_WEB_ITEMS,
                ECustomWorkflowTransitionRecipeTaskName.GENERATE_POS_ITEMS,
                ECustomWorkflowTransitionRecipeTaskName.GENERATE_PRINT_ITEMS,
                ECustomWorkflowTransitionRecipeTaskName.GENERATE_WEB_ITEMS,
                ECustomWorkflowTransitionRecipeTaskName.UPDATE_INDESIGN_CONTENT,
                ECustomWorkflowTransitionRecipeTaskName.EXECUTE_AUTOMATIONS,
                ECustomWorkflowTransitionRecipeTaskName.MOVE_UPLOADS,
                ECustomWorkflowTransitionRecipeTaskName.UPLOAD_ITEM_FILES
            ]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.DELETE_ORPHANED_NOTES,
            description: 'Set notes status to DELETED if they are linked to a campaign item which is no longer used in ' +
                'the layout of any publication item in the publication.',
            progressTitle: 'Orphaned notes deleted'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.CREATE_LINKS,
            description: 'Create upload links for publication items',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            progressTitle: 'Links created'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.SEND_LINKS,
            description: 'Send publication item upload links to configured receivers.',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            progressTitle: 'Links sent'
        }
    ];

export class CustomWorkflowTransitionRecipeTaskModel {
    @autoserialize public name: ECustomWorkflowTransitionRecipeTaskName;
    // TODO: make this more strict when we have a better structure of parameters
    @autoserialize public parameters: Record<string, any>;
}

export class CustomWorkflowTransitionModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public title: string;
    @autoserialize public to: string;
    @autoserialize public from: string;
    @autoserializeAs(CustomWorkflowTransitionRecipeTaskModel) public recipe: CustomWorkflowTransitionRecipeTaskModel[];

    public static getRecipeTaskProgressTitle(recipeTask: ECustomWorkflowTransitionRecipeTaskName): string {
        return CUSTOM_WORKFLOW_TRANSITION_RECIPE_TASKS.find(recipeConfig => recipeConfig.task === recipeTask)?.progressTitle || 'Items done';
    }
}
