import {autoserialize, autoserializeAs} from 'cerialize';
import {UserModel} from './user.model';
import {VariantModel} from './variant.model';
import {AfterEffectsProjectFileModel} from './after-effects-project-file.model';

export enum EVideoEngineType {
    AFTER_EFFECTS = 'AFTER_EFFECTS'
}

export class VideoTemplateVariantPresetModel {
    @autoserializeAs(VariantModel) public variant: VariantModel;
    @autoserializeAs(AfterEffectsProjectFileModel) afterEffectsProjectFile: AfterEffectsProjectFileModel;
    @autoserialize public videoRuleSet: string; // TODO: update with correct model reference
}

export class VideoTemplateModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public videoRuleSet: string; // TODO: update with correct model reference
    @autoserialize public engineType: string;
    @autoserializeAs(AfterEffectsProjectFileModel) afterEffectsProjectFile: AfterEffectsProjectFileModel;
    @autoserializeAs(VideoTemplateVariantPresetModel) variantPresets: VideoTemplateVariantPresetModel[];
    @autoserializeAs(UserModel) public createdBy: UserModel;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
}
