import {autoserialize, autoserializeAs} from 'cerialize';
import {DataFieldModel} from './data-field.model';
import {FormGroup} from '@angular/forms';

export enum EProducerAction {
    UPSERT = 'UPSERT',
    DELETE = 'DELETE'
}

export enum EConnectionType {
    MEDIA_VALET = 'MEDIA_VALET',
    WEBHOOK_CONSUMER = 'WEBHOOK_CONSUMER',
    SUPERUNIE_ADAM = 'SUPERUNIE_ADAM',
    WEBHOOK_PRODUCER = 'WEBHOOK_PRODUCER'
}

export enum EWebhookEvent {
    CAMPAIGN_CREATED = 'CAMPAIGN_CREATED'
}

export enum EWebhookProducerCollectionNames {
    CAMPAIGN_ITEM = 'RLCampaignItem',
    PRODUCT = 'RLProduct',
}

export class WebhookEvent {
    @autoserialize public Name: string;
}

export class WebhookMetaData {
    @autoserialize public dataFieldName: string;
    @autoserialize public name: string;
}

export class WebhookModel {
    @autoserialize public _id: string;
    @autoserializeAs(WebhookEvent) public Events: WebhookEvent[];
    @autoserialize public categories: string[];
    @autoserialize public SubscriptionId: string;
    @autoserialize public Status: string;
    @autoserializeAs(Object) public Data: { webhook: string };
    @autoserializeAs(WebhookMetaData) public metaData: WebhookMetaData[];
}

export class WebhookPostBody {
    @autoserialize public event: string;
    @autoserialize public categories: string[];
    @autoserializeAs(WebhookMetaData) public metaData: WebhookMetaData[];

    constructor(event: string, categories: string[], metaData?: WebhookMetaData[]) {
        this.event = event;
        this.categories = categories;
        this.metaData = metaData;
    }
}

export class WebhookPatchBody {
    @autoserializeAs(WebhookMetaData) public metaData: WebhookMetaData[];

    constructor(metaData: WebhookMetaData[]) {
        this.metaData = metaData;
    }
}

export class ConsumerSettingsModel {
    @autoserialize public secret: string;
    @autoserialize public authentication: string;
}

export class SynchronisationModel {
    @autoserialize public images: ImageSynchronisationModel[];
    @autoserialize public products: ProductSynchronisationModel[];
}

export class ImageSynchronisationModel {
    @autoserialize public _id: string;
    @autoserialize public enabled: boolean;
    @autoserialize public regexFilter: string;
    @autoserialize public productDataField: DataFieldModel;
    @autoserialize public removeLeadingZeros: boolean;
}

export class ImageSynchronisationBody {
    @autoserialize public enabled: boolean;
    @autoserialize public regexFilter: string;
    @autoserialize public productDataField: string;
    @autoserialize public removeLeadingZeros: boolean;

    constructor(enabled: boolean,
                regexFilter: string,
                productDataField: string,
                removeLeadingZeros: boolean) {
        this.enabled = enabled;
        this.removeLeadingZeros = removeLeadingZeros;
        this.regexFilter = regexFilter || null;
        this.productDataField = productDataField || null;
    }
}

export class ProductSynchronisationModel {
    @autoserialize public _id: string;
    @autoserialize public enabled: boolean;
    @autoserialize public mapping: ConnectionMappingModel[];
    @autoserialize public productDataField: DataFieldModel;
    @autoserialize public removeLeadingZeros: boolean;
}

export class ProductSynchronisationBody {
    @autoserialize public enabled: boolean;
    @autoserialize public mapping: ConnectionMappingModel[];
    @autoserialize public productDataField: string;
    @autoserialize public removeLeadingZeros: boolean;

    constructor(enabled: boolean,
                mapping: ConnectionMappingModel[],
                productDataField: string,
                removeLeadingZeros: boolean) {
        this.enabled = enabled;
        this.mapping = mapping || null;
        this.productDataField = productDataField || null;
        this.removeLeadingZeros = removeLeadingZeros;
    }
}

export class ConnectionMappingModel {
    @autoserialize public path: string;
    @autoserialize public property: string;
    constructor(dataField: string, propertyPath: string) {
        this.path = dataField;
        this.property = propertyPath;
    }
}

export class ConnectionEventModel {
    @autoserialize public _id: string;
    @autoserialize public event: string;
    @autoserialize public endpoint: string;
}

export class ConnectionEventBody {
    @autoserialize public event: string;
    @autoserialize public endpoint: string;

    constructor(event: string, endpoint: string) {
        this.event = event;
        this.endpoint = endpoint;
    }
}

export class ConnectionEventPatchBody {
    @autoserialize public event: string;
    @autoserialize public endpoint: string;

    constructor(event?: string, endpoint?: string) {
        if (event) this.event = event;
        if (endpoint) this.endpoint = endpoint;
    }
}

export class ConnectionEndpointModel {
    @autoserialize public _id: string;
    @autoserialize public collectionName: EWebhookProducerCollectionNames;
    @autoserialize public action: EProducerAction;
    @autoserialize public identifierPath: string;
    @autoserialize public identifierField: string;
    @autoserialize public importMasterBriefing: boolean;
    @autoserialize public campaignNamePath: string;
    @autoserialize public campaignDatePath: string;
    @autoserializeAs(ConnectionMappingModel) public mappings: ConnectionMappingModel[];
}

export class ConnectionModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public connectionType: EConnectionType;
    // MEDIA_VALET related properties
    @autoserialize public clientId: string;
    @autoserialize public clientSecret: string;
    @autoserialize public authUrl: string;
    @autoserialize public hasRefreshToken: boolean;
    @autoserialize public subscriptionKey: string;
    @autoserializeAs(WebhookModel) public webhooks: WebhookModel[];
    // WEBHOOK_CONSUMER related properties
    @autoserializeAs(ConnectionEventModel) public events: ConnectionEventModel[];
    @autoserializeAs(ConsumerSettingsModel) public consumerSettings: ConsumerSettingsModel;
    // SUPERUNIE_ADAM related properties
    @autoserialize public apiKey: string;
    @autoserialize public globalLocationNumbers: string[];
    @autoserializeAs(SynchronisationModel) public synchronisations: SynchronisationModel;
    // WEBHOOK_PRODUCER related properties
    @autoserialize public authenticationToken: string;
    @autoserialize public secret: string;
    @autoserializeAs(ConnectionEndpointModel) public endpoints: ConnectionEndpointModel[];

    public static getLogo(connectionType: EConnectionType): string {
        switch (connectionType) {
            case EConnectionType.MEDIA_VALET:
                return '/assets/images/logos/media_valet_logo.png';
            case EConnectionType.WEBHOOK_CONSUMER:
                return '/assets/images/logos/webhook_consumer_logo.png';
            case EConnectionType.WEBHOOK_PRODUCER:
                return '/assets/images/logos/webhook_producer_logo.png';
            case EConnectionType.SUPERUNIE_ADAM:
                return '/assets/images/logos/superunie_adam_logo.jpeg';
            default:
                return '';
        }
    }
}

export class ConnectionApiModel {
    @autoserialize public name: string;
    @autoserialize public connectionType: EConnectionType;
    @autoserialize public clientId: string;
    @autoserialize public clientSecret: string;
    @autoserialize public subscriptionKey: string;
    @autoserialize public apiKey: string;
    @autoserialize public globalLocationNumbers: string[];
    @autoserializeAs(SynchronisationModel) public synchronisations: SynchronisationModel;
    @autoserialize public authenticationToken: string;
    @autoserialize public secret: string;

    constructor(name: string, type: EConnectionType, clientId?: string, clientSecret?: string,
                subscriptionKey?: string, apiKey?: string, globalLocationNumbers?: string[]) {
        this.name = name;
        this.connectionType = type;
        if (clientId) this.clientId = clientId;
        if (clientSecret) this.clientSecret = clientSecret;
        if (subscriptionKey) this.subscriptionKey = subscriptionKey;
        if (apiKey) this.apiKey = apiKey;
        if (globalLocationNumbers) this.globalLocationNumbers = globalLocationNumbers;
    }
}

export class ConnectionEndpointBody {
    @autoserialize public collectionName: EWebhookProducerCollectionNames;
    @autoserialize public action: EProducerAction;
    @autoserialize public identifierPath: string;
    @autoserialize public identifierField: string;
    @autoserialize public importMasterBriefing: boolean;
    @autoserialize public campaignNamePath: string;
    @autoserialize public campaignDatePath: string;
    @autoserializeAs(ConnectionMappingModel) public mappings: ConnectionMappingModel[];

    static fromFormGroup(formGroup: FormGroup): ConnectionEndpointBody {
        if (!formGroup.valid) throw 'Form not valid.';

        const body = new ConnectionEndpointBody();

        body.collectionName = formGroup.get('model')?.value.value;
        body.action = formGroup.get('action')?.value.value;
        body.identifierPath = formGroup.get('path')?.value;
        body.identifierField = formGroup.get('field')?.value.value;
        if (formGroup.get('importMasterBriefing')) body.importMasterBriefing = formGroup.get('importMasterBriefing')?.value;
        if (formGroup.get('campaignNamePath')) body.campaignNamePath = formGroup.get('campaignNamePath')?.value;
        if (formGroup.get('campaignDatePath')) body.campaignDatePath = formGroup.get('campaignDatePath')?.value;
        body.mappings = formGroup.get('mappings')?.value.map((mapping) => new ConnectionMappingModel(mapping.path, mapping.property.path));

        return body;
    }
}

export class ConnectionPatchModel {
    @autoserialize public apiKey: string;
    @autoserialize public hasRefreshToken: boolean;

    constructor(apiKey?: string) {
        this.apiKey = apiKey;
    }
}
