import {Component, DestroyRef, Inject, inject, OnInit} from '@angular/core';
import {
    FormArray,
    FormControl,
    FormControlStatus,
    FormGroup,
    Validators
} from '@angular/forms';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {
    BUTTON_TYPE,
    ButtonConfig, DropdownComponent,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {VariantModel} from '../../models/api/variant.model';
import {AfterEffectsProjectFilesApiService} from '../../api/services/after-effects-project-files.api.service';
import {VariantService} from '../../api/services/variant.service';
import {Toaster} from '../../classes/toaster.class';
import {AfterEffectsProjectFileModel} from '../../models/api/after-effects-project-file.model';
import {forkJoin, startWith} from 'rxjs';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {
    EVideoEngineType,
    VideoTemplateModel,
} from '../../models/api/video-template.model';
import {VideoTemplateApiService} from '../../api/services/video-template.api.service';
import {ModelUtil} from '../../classes/model.util';
import {distinctUntilChanged, finalize} from 'rxjs/operators';

interface IVideoTemplateModalData {
    videoTemplate: VideoTemplateModel
}

class VideoTemplateFormGroup {
    name: FormControl<string>;
    videoRuleSet: FormControl<DropdownItem<any>>; // TODO: update with correct type
    engineType: FormControl<DropdownItem<EVideoEngineType>>;
    afterEffectsProjectFile: FormControl<AfterEffectsProjectFileModel>;
    variantsPresets: FormArray<FormGroup<VideoTemplateVariantPresetFormGroup>>;
}

class VideoTemplateVariantPresetFormGroup {
    variant: FormControl<VariantModel>;
    afterEffectsProjectFile: FormControl<AfterEffectsProjectFileModel>;
    videoRuleSet: FormControl<DropdownItem<any>>; // TODO: update with correct type
}

@Component({
    selector: 'video-template-form',
    templateUrl: './video-template-form.component.html',
    styleUrl: './video-template-form.component.scss'
})
export class VideoTemplateFormComponent implements OnInit {
    private afterEffectsProjectFilesApiService = inject(AfterEffectsProjectFilesApiService);
    private videoTemplateApiService = inject(VideoTemplateApiService);
    private fullModalService = inject(FullModalService);
    private variantService = inject(VariantService);
    private destroyRef: DestroyRef = inject(DestroyRef);

    private videoTemplate: VideoTemplateModel;

    // full modal related
    private saveButton: ButtonConfig;

    // form related
    public formGroup: FormGroup<VideoTemplateFormGroup>;

    public variantPresetFormArray: FormArray<FormGroup<VideoTemplateVariantPresetFormGroup>> = new FormArray([]);

    public variants: VariantModel[] = [];
    public selectedVariants: VariantModel[] = [];

    public videoEngineTypes = [
        new DropdownItem('After Effects', EVideoEngineType.AFTER_EFFECTS)
    ];

    public afterEffectsProjectFiles: AfterEffectsProjectFileModel[] = [];
    public afterEffectsProjectFilesTotalItems = 0;
    public afterEffectsProjectFilesOffset = 0;

    // TODO: get video rulesets
    public videoRulesets = [
        new DropdownItem('Ruleset 1', '5afd3e27ac14d5926d785c80'),
        new DropdownItem('Ruleset 2', '5afd3e27ac14d5926d785c81')
    ]

    constructor(@Inject(NUC_FULL_MODAL_DATA) private modalData: IVideoTemplateModalData) {
        if (this.modalData?.videoTemplate) this.videoTemplate = this.modalData.videoTemplate;
    }

    public ngOnInit(): void {
        this.initForm();
        this.initButtons();

        this.getData();
    }

    private getData() {
        forkJoin([
            this.variantService.getVariants(),
            this.afterEffectsProjectFilesApiService
                .find(DropdownComponent.DEFAULT_LIMIT, this.afterEffectsProjectFilesOffset)
            // TODO: add get video rulesets call here
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([variantResults, afterEffectsProjectFileResults]) => {
                    this.afterEffectsProjectFilesTotalItems = afterEffectsProjectFileResults.total;
                    this.afterEffectsProjectFilesOffset += afterEffectsProjectFileResults.items.length;

                    this.afterEffectsProjectFiles = this.afterEffectsProjectFiles
                        .filter(item => !afterEffectsProjectFileResults.items.some(newItem => newItem._id === item._id))
                        .concat(afterEffectsProjectFileResults.items);

                    this.variants = variantResults.items;
                    if (this.videoTemplate) this.initFormValues();
                },
                error: Toaster.handleApiError
            })
    }

    private initForm(): void {
        this.formGroup = new FormGroup<VideoTemplateFormGroup>({
            name: new FormControl(this.modalData.videoTemplate?.name, Validators.required),
            videoRuleSet: new FormControl(undefined, Validators.required),
            engineType: new FormControl({value: this.videoEngineTypes[0], disabled: true}, Validators.required),
            afterEffectsProjectFile: new FormControl(undefined, Validators.required),
            variantsPresets: this.variantPresetFormArray
        })
    }

    private initFormValues(): void {
        // update after effects project controls
        let afterEffectsProjectFile = this.afterEffectsProjectFiles
            .find((afterEffectsProjectFile) =>
                afterEffectsProjectFile._id === this.modalData.videoTemplate.afterEffectsProjectFile._id
            );

        // afterEffectsProjectFiles is a paged call, the item might not be in current list,
        // so we try to find it and if no result, add the item to the array
        if (!afterEffectsProjectFile) {
            afterEffectsProjectFile = this.modalData.videoTemplate.afterEffectsProjectFile;
            this.afterEffectsProjectFiles = this.afterEffectsProjectFiles.concat(afterEffectsProjectFile);
        }

        this.formGroup.controls.afterEffectsProjectFile.setValue(afterEffectsProjectFile);

        // update video rule set control
        const videoRuleSetValue = this.videoRulesets
            .find((videoRuleSet) =>
                videoRuleSet.getValue() === this.modalData.videoTemplate.videoRuleSet
            );

        this.formGroup.controls.videoRuleSet.setValue(videoRuleSetValue);

        // update variants form groups
        this.selectedVariants = this.modalData.videoTemplate.variantPresets.map(preset =>
            this.variants.find(variant => variant._id === preset.variant._id));

        this.modalData.videoTemplate.variantPresets.forEach((variantPreset) => {
            const variant = this.variants.find(variant => variant._id === variantPreset.variant._id);
            let afterEffectsProjectFile = this.afterEffectsProjectFiles
                .find(afterEffectsProjectFile => afterEffectsProjectFile._id === variantPreset.afterEffectsProjectFile._id);

            if (!afterEffectsProjectFile) {
                afterEffectsProjectFile = variantPreset.afterEffectsProjectFile;
                this.afterEffectsProjectFiles = this.afterEffectsProjectFiles.concat(afterEffectsProjectFile);
            }

            const videoRuleSet = this.videoRulesets
                .find((videoRuleSet) =>
                    videoRuleSet.getValue() === variantPreset.videoRuleSet
                );

            this.createAndInsertVariantPresetFormGroup(variant, afterEffectsProjectFile, videoRuleSet)
        });
    }

    private initButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, false);

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.onSaveVideoTemplate());

        const actions = [cancelAction, saveAction];
        this.fullModalService.setModalActions(actions);

        this.formGroup.statusChanges.pipe(
            startWith(this.formGroup.status),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: FormControlStatus) => this.saveButton.disabled = status !== 'VALID');
    }
    private onSaveVideoTemplate(): void {
        this.saveButton.loading = true;

        const formValue = this.formGroup.getRawValue();
        const variantPresetsBody = formValue.variantsPresets.map((variantPreset) => {
            return {
                variant: variantPreset.variant._id,
                afterEffectsProjectFile: variantPreset.afterEffectsProjectFile._id,
                videoRuleSet: variantPreset.videoRuleSet.getValue()
            }
        })

        const body = ModelUtil.createApiBody({
            name: formValue.name,
            videoRuleSet: formValue.videoRuleSet.getValue(),
            engineType: formValue.engineType.getValue(),
            afterEffectsProjectFile: formValue.afterEffectsProjectFile._id,
            variantPresets: variantPresetsBody
        }, this.videoTemplate?._id)

        if (this.videoTemplate) {
            this.videoTemplateApiService.patch(this.videoTemplate._id, body)
                .pipe(
                    finalize(() => this.saveButton.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: () => {
                        Toaster.success('Video template updated successfully');
                        this.fullModalService.close({});
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.videoTemplateApiService.create(body)
                .pipe(
                    finalize(() => this.saveButton.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: () => {
                        Toaster.success('Video template created successfully');
                        this.fullModalService.close({});
                    },
                    error: Toaster.handleApiError
                });
        }
    }
    public getAfterEffectsProjectFilesWithSearch(search: string): void {
        this.afterEffectsProjectFilesApiService
            .find(DropdownComponent.DEFAULT_LIMIT, this.afterEffectsProjectFilesOffset, null, null, search)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.afterEffectsProjectFilesTotalItems = result.total;
                    this.afterEffectsProjectFilesOffset += result.items.length;

                    this.afterEffectsProjectFiles = this.afterEffectsProjectFiles
                        .filter(item => !result.items.some(newItem => newItem._id === item._id))
                        .concat(result.items);
                },
                error: Toaster.handleApiError
            });
    }

    public requestAfterEffectsProjectFiles(event?: IDropdownRequestDataEvent): void {
        if (event.reset) {
            this.afterEffectsProjectFiles = [];
            this.afterEffectsProjectFilesTotalItems = 0;
            this.afterEffectsProjectFilesOffset = 0;
        }

        this.getAfterEffectsProjectFilesWithSearch(event.search);
    }

    // Determine which variant is added/removed
    public onVariantsChanged(variants: VariantModel[]): void {
        // Check Removed
        const deletedVariant = this.selectedVariants.find(selectedVariant => !variants.find(variant => variant._id === selectedVariant._id));
        if (deletedVariant) {
            this.variantPresetFormArray.controls.forEach((control, index) => {
                if (control.value.variant._id === deletedVariant._id) {
                    this.variantPresetFormArray.removeAt(index);
                }
            });
        }

        // Check Added
        const addedVariant = variants.find(variant => !this.selectedVariants.find(selectedVariant => selectedVariant._id === variant._id));
        this.selectedVariants = variants;

        if (addedVariant) {
            this.createAndInsertVariantPresetFormGroup(addedVariant)
        }
    }

    public createAndInsertVariantPresetFormGroup(
        addedVariant: VariantModel,
        afterEffectsProjectFile?: AfterEffectsProjectFileModel,
        videoRuleSet?: any // TODO: update with correct type
    ): void {
        const variantPresetFormGroup = new FormGroup<VideoTemplateVariantPresetFormGroup>({
            variant: new FormControl(addedVariant, Validators.required),
            afterEffectsProjectFile: new FormControl(afterEffectsProjectFile || undefined, Validators.required),
            videoRuleSet: new FormControl(videoRuleSet || undefined, Validators.required),
        });
        const insertAt = this.variants.findIndex(variant => variant._id === addedVariant._id);
        this.variantPresetFormArray.insert(insertAt, variantPresetFormGroup);
    }
}
