import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormatRulesetService} from '../../../../api/services/format-ruleset.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormatRulesetModel} from '../../models/api/format-ruleset.model';
import {Subject, Subscription} from 'rxjs';
import {
    BUTTON_TYPE,
    ESortOrder,
    FullModalService,
    IActionClickEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {FormatRulesetBaseItem, FormatRulesetItemModel} from '../../models/api/format-ruleset-item.model';
import {takeUntil} from 'rxjs/operators';
import {RulePropertyModel} from '../../../../models/api/rule-property.model';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {ISortOptionEvent} from '@relayter/rubber-duck/lib/modules/table/components/table/table-config';
import {SortDirection} from '@angular/material/sort';
import {DesignLibraryService} from '../../../../api/services/design-library.service';
import {PropertyService} from '../../../../api/services/property.service';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {FormatRulesetAssetItemModel} from '../../models/api/format-ruleset-asset-item.model';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {FormatRulesetItemGroupModel} from '../../models/api/format-ruleset-item-group.model';

@Component({ template: '' })
export abstract class FormatRulesetItemTableComponent extends RLTableComponent implements OnInit, OnDestroy {
    @Output() public itemClicked = new EventEmitter<FormatRulesetItemModel | FormatRulesetAssetItemModel | FormatRulesetItemGroupModel>();
    public items: any[];
    public tableId: string = 'ruleset-item-table';
    public subscription: Subscription;
    public ruleSet: FormatRulesetModel;
    public pageIndex: number = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize: number = AppConstants.PAGE_SIZE_DEFAULT;
    public sortProperty: string;
    public sortOrder: SortDirection;
    public search: string;
    public itemGroup: FormatRulesetItemGroupModel;
    public actions: ITableAction[];
    public columns: ITableColumn[] = [];
    public total: number;
    public disableNextPage = false;
    public apiPath: string;
    @Input() protected ruleSetId: string;
    @Input() protected ruleProperties: RulePropertyModel[];
    protected onDestroySubject = new Subject<void>();
    public itemGroups: FormatRulesetItemGroupModel[] = [];

    protected constructor(userSettingsStorageService: UserSettingsStorageService,
                protected formatRulesetService: FormatRulesetService,
                protected indesignLibraryService: DesignLibraryService,
                protected propertyService: PropertyService,
                protected userIsAllowedToPipe: UserIsAllowedToPipe,
                protected route: ActivatedRoute,
                protected router: Router,
                protected dialogService: NucDialogService,
                protected fullModalService: FullModalService,
                protected paginatorService: PaginatorService) {
        super(userSettingsStorageService);
    }

    protected static generateNewRuleName(base, allNames) {
        const baseName = base + ' (copy)';
        let newName = baseName;
        let count = 0;
        while (allNames.includes(newName)) {
            count++;
            newName = baseName + count;
        }
        return newName;
    }

    public ngOnInit(): void {
        this.apiPath = this.route.snapshot.data.apiPath;
        this.ruleSetId = this.route.snapshot.params.ruleSetId;

        this.setupTableColumns();
        this.setTableActions();

        this.paginatorService.getPagination(this.tableId).subscribe((results) => {
            this.pageIndex = results.pageIndex;
            this.pageSize = results.pageSize;

            this.getRulesetItems();
        });

        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    public reloadTable() {
        this.getRulesetItems();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        if (this.actions.includes(event.action)) {
            this.getFormatRulesetItem(event.item)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe((rulesetItem) => {
                        switch (event.action) {
                            case AppConstants.TABLE_ACTION_TYPES.EDIT:
                                this.itemClicked.emit(rulesetItem);
                                break;
                            case AppConstants.TABLE_ACTION_TYPES.DELETE:
                                this.openDeleteDialog(rulesetItem);
                                break;
                            case AppConstants.TABLE_ACTION_TYPES.COPY:
                                this.openCopyRuleDialog(rulesetItem);
                                break;
                            default:
                                return;
                        }
                    },
                    (error) => {
                        this.getRulesetItems();
                        Toaster.handleApiError(error);
                    });
        }
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        if (event.column?.sortProperty) {
            this.sortProperty = event.column.sortProperty;
            this.sortOrder = event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = null;
            this.sortOrder = null;
        }

        this.setPageIndex();
    }

    public onSearchBarValueUpdated(event: string): void {
        if (this.search !== event) {
            this.search = event;
            this.setPageIndex();
        }
    }

    protected abstract setTableActions(): void;

    protected abstract getRulesetItems(): void;

    protected abstract getFormatRulesetItem(item);

    public abstract setupTableColumns(): void;

    protected abstract deleteRule(rule: FormatRulesetBaseItem): void;

    protected abstract copyRule(rule: any): void;

    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private openDeleteDialog(rule: FormatRulesetBaseItem): void {
        const dialogConfig =
            new NucDialogConfigModel('Delete rule', `Please confirm that you wish to delete rule: ${rule.name || ''}`);
        const dialog = this.dialogService.openDialog(dialogConfig);
        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
        dialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            this.deleteRule(rule);
            dialog.close();
        });
    }

    private openCopyRuleDialog(rule: FormatRulesetBaseItem): void {
        const copyDialogConfig = new NucDialogConfigModel('Duplicating rule',
            'Are you sure you want to duplicate the selected rule?');
        const copyDialog = this.dialogService.openDialog(copyDialogConfig);
        copyDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => copyDialog.close());
        copyDialogConfig.addAction('Proceed', BUTTON_TYPE.PRIMARY).subscribe(() => {
            copyDialog.close();
            this.copyRule(rule);
        });
    }
}
