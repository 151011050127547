import {tap} from 'rxjs/operators';
import {DestroyRef, inject, Inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {BehaviorSubject, Observable, Subscriber} from 'rxjs';
import {UpdateUserModel, UserDetailModel, UserModel} from '../../models/api/user.model';
import {environment} from '../../../environments/environment';
import {ARApiError, ARApiUrlBuilderService, ARRequestOptions, ARResponseModel} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {UserStorage} from '../../classes/user-storage.class';
import {ChangePassword} from '../../models/api/change-password.model';
import {LoginBodyModel, LoginResponseModel} from '../../models/api/login.model';
import {AppConstants} from '../../app.constants';
import {Router} from '@angular/router';
import {Auth0TokenModel} from '../../models/api/auth0-token-model';
import {TokenModel} from '../../models/api/token.model';
import {Deserialize, Serialize} from 'cerialize';
import {Auth0Service} from '../../services/auth0.service';
import {ConfigService} from './config-service';
import {BUTTON_TYPE, FullModalService, NucDialogConfigModel, NucDialogService} from '@relayter/rubber-duck';
import {RegistrationModel} from '../../models/api/registration.model';
import {ISegmentService, SEGMENT_SERVICE} from '../../services/segment/segment.service.interface';
import Bugsnag from '@bugsnag/js';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseApiRequestService {
    private destroyRef = inject(DestroyRef);
    private loggedInUserSubject: BehaviorSubject<UserDetailModel> = new BehaviorSubject<UserDetailModel>(null);

    static readonly BUGSNAG_META_DATE_TEAM_KEY = 'Team';

    /**
     * Centralized login
     */
    private handleLoginResponse(loginResponse: LoginResponseModel): void {
        UserStorage.setAccessToken(loginResponse.token);
    }

    /**
     * @constructor
     */
    constructor(private configService: ConfigService,
                private router: Router,
                private auth0: Auth0Service,
                private dialogService: NucDialogService,
                private fullModalService: FullModalService,
                @Inject(SEGMENT_SERVICE) private segmentService: ISegmentService) {
        super();
        this.subscribeToUserUpdates();
    }

    /**
     * Angular lifecycle event
     */
    public init(): Observable<UserModel> {
        // this is always called in the canActivate guard, so we set signInUserHere once
        return this.getMe().pipe(
            tap((user: UserDetailModel) => {
                this.loggedInUserSubject.next(user);

                Bugsnag.addMetadata(UserService.BUGSNAG_META_DATE_TEAM_KEY, {
                    name: user.team.name,
                    id: user.team._id
                });
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private subscribeToUserUpdates(): void {
        this.loggedInUserSubject
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((user) => {
                this.segmentService.setUser(user);
            });
    }

    /**
     * Clear the current logged-in user from the loggedInUserSubject
     */
    public clearLoggedInUser(): void {
        Bugsnag.clearMetadata(UserService.BUGSNAG_META_DATE_TEAM_KEY);
        this.loggedInUserSubject.next(null);
    }

    /**
     * Get Subject to listen for updates of the logged-in user, filter out the empty results send when there is no user available
     * @return {Observable<UserDetailModel>}
     */
    public getUserUpdates(): Observable<UserDetailModel> {
        return this.loggedInUserSubject.asObservable();
    }

    /**
     * Get the last announced user
     * @return {UserDetailModel}
     */
    public getLoggedInUser(): UserDetailModel {
        return this.loggedInUserSubject.getValue();
    }

    /**
     * Get your own details
     * @returns {Observable<UserDetailModel>}
     */
    public getMe(): Observable<UserDetailModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_USERS, ApiConstants.API_METHOD_ME]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, UserDetailModel);
        });
    }

    /**
     * Post a new password forgotten
     * @param email
     * @return {Observable<boolean>}
     */
    public postUserPasswordForgottenWithEmail(email: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_USERS, ApiConstants.API_METHOD_FORGOT_PASSWORD]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {
            email
        };
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    public postRegisterUser(registration: RegistrationModel): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_USERS, ApiConstants.API_METHOD_REGISTER]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = registration;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    /**
     * Change the password for currently logged-in user
     * @param changePassword
     * @return {Observable<boolean>}
     */
    public changePasswordMe(changePassword: ChangePassword): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_USERS, ApiConstants.API_METHOD_ME,
            ApiConstants.API_METHOD_CHANGE_PASSWORD]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = {
            oldPassword: changePassword.oldPassword,
            newPassword: changePassword.newPassword
        };
        return new Observable((obs) => {
            this.doRequest(options).subscribe({
                next: (res: ARResponseModel) => {
                    let token: string;
                    if (res.data && res.data['token']) {
                        token = res.data['token'];
                        UserStorage.setAccessToken(token);
                        obs.next(true);
                    } else {
                        obs.next(false);
                    }
                    obs.complete();
                },
                error: (err: ARApiError) => {
                    // real app breaking error log to bugsnag?
                    this.handleError(err);
                    obs.error(err);
                }
            });
        });
    }

    /**
     * Get the details of a user
     * @param {string} id
     * returns {Observable<UserModel>}
     */
    public getUserDetails(id: string): Observable<UserDetailModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_USERS, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, UserDetailModel);
        });
    }

    /**
     * Patch the details of a user
     *
     * @param {string} id
     * @param {UserDetailModel} user
     * returns {Observable<UserModel>}
     */
    public patchUserDetails(id: string, user: UpdateUserModel): Observable<UserDetailModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_USERS, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = Serialize(user, UpdateUserModel);
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, UserDetailModel);
        });
    }

    /**
     * Delete a user from the database
     * @param id
     * @return {any}
     */
    public deleteUser(id: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_USERS, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    /**
     * Login call by means of email + password
     */
    public loginWithCredentials(login: LoginBodyModel): Observable<LoginResponseModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_USERS, ApiConstants.API_METHOD_LOGIN]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = login;
        return new Observable((obs) => this.handleDetailResponse(options, obs, LoginResponseModel)).pipe(
            tap((loginResponse: LoginResponseModel) => this.handleLoginResponse(loginResponse)));
    }

    /**
     * Let Relayter API know we need to log out
     */
    public logout(): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_USERS, ApiConstants.API_METHOD_LOGOUT]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    /**
     * Logout from Auth0
     */
    public logoutAuth0(): void {
        this.auth0.logout();
    }

    /**
     * Login with Auth0 configured providers
     */
    public loginAuth0(tokens: Auth0TokenModel): Observable<TokenModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_EXCHANGE_AUTH0_ID_TOKEN]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        // Secure API calls with the signed a bearer access token from auth0
        options.headers = options.headers.set('Authorization', 'Bearer ' + tokens.accessToken);
        options.body = {
            grant_type: 'authorization_code',
            code: tokens.idToken,
            client_id: environment.OAUTH_CLIENT_ID
        };
        // Before login, clear all user authentication data
        this.emptyCredentials();
        return new Observable<any>((obs) => {
            this.handleAuth0LoginResponse(options, obs, TokenModel);
        });
    }

    /**
     * Empty local storage
     */
    public emptyCredentials(): void {
        UserStorage.removeUser();
        this.clearLoggedInUser();
        UserStorage.removeAccessToken();
        this.configService.removeConfig();
    }

    /**
     * Logout from the user and config storage and redirect to log-in if needed
     * @param {boolean} noRedirect
     * @param {string} returnUrl
     */
    public logoutAndRedirectToLogin(noRedirect: boolean = false, returnUrl?: string): void {
        this.emptyCredentials();

        if (!noRedirect) {
            const options = {state: {skipGuards: true}};
            if (returnUrl) {
                options['queryParams'] = {returnUrl: returnUrl};
            }

            this.router.navigate([AppConstants.LOGIN_REDIRECT], options);
        }
    }

    /**
     * Log out user with session opened on other device
     */
    public forcedSessionClose(): void {
        const confirmDialogConfig = new NucDialogConfigModel(`Session expired`,
            `There is another session active with this account. You will now be logged out.`);
        const confirmDialog = this.dialogService.openDialog(confirmDialogConfig);
        confirmDialogConfig.addAction('Confirm', BUTTON_TYPE.PRIMARY).subscribe(() => {
            confirmDialog.close();
        });
        confirmDialog.beforeClosed().subscribe(() => {
            // Close any opened full modal
            if (this.fullModalService.openedModal) {
                this.fullModalService.close();
            }
            this.logoutAndRedirectToLogin();
        });
    }

    /**
     * Logout user
     */
    private logoutAuth0User(): void {
        this.logoutAuth0();
        this.logoutUser();
    }

    /**
     * Logout user
     */
    private logoutUser(): void {
        this.emptyCredentials();
        UserStorage.removeClientId();
    }

    /**
     * Handle the Auth0 login response with the TokenModel to the standard LoginResponseModel
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    protected handleAuth0LoginResponse(options: ARRequestOptions, obs: Subscriber<TokenModel>, model: Function): void {
        this.doRequest(options).subscribe({
            next: (res: ARResponseModel) => {
                if (res.data) {
                    const rlToken: TokenModel = Deserialize(res.data, model);

                    const loginResponse: LoginResponseModel = new LoginResponseModel();
                    loginResponse.token = rlToken.token;
                    loginResponse.user = rlToken.user;
                    // Announce new logged-in user to user subject
                    this.handleLoginResponse(loginResponse);
                    obs.next(rlToken);
                } else {
                    this.logoutAuth0User();
                    obs.error(new Error('No token returned'));
                }
                obs.complete();
            },
            error: (err) => {
                // real app breaking error log to Bugsnag?
                this.logoutUser();
                obs.error(err);
            }
        });
    }
}
