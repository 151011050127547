import {EPublicationType} from '../publication-type.enum';
import {TemplateTypeModel} from '../../../../../models/api/template-type.model';
import {DropdownItem} from '../../../../../models/ui/dropdown-item.model';
import {MasterPageModel} from '../../../../../models/api/master-page.model';
import {VariantModel} from '../../../../../models/api/variant.model';
import {DesignLibraryModel} from '../../../../../modules/format-rulesets/models/api/design-library.model';
import {FormatRulesetModel} from '../../../../../modules/format-rulesets/models/api/format-ruleset.model';

export class TemplatePresetModel {

    public name: string;
    public tags: DropdownItem<string>[] = [];
    public masterPage: MasterPageModel;
    public library: DesignLibraryModel;
    public ruleSet: FormatRulesetModel;
    public templateType: TemplateTypeModel;

    public static fromFormValues(formValues: any): TemplatePresetModel {
        // formValues.templateType is not set for Print, only for pos
        let templateType;
        if (formValues.templateType && formValues.templateType.length) {
            templateType = formValues.templateType[0];
        }

        const masterPage = formValues.masterPage;
        const libraryId = formValues.library;
        const ruleSetId = formValues.ruleSet;

        return new TemplatePresetModel(formValues.name, formValues.tags, masterPage, libraryId, ruleSetId, templateType);
    }

    constructor(name?: string, tags?: DropdownItem<string>[], masterPage?: MasterPageModel, libraryId?: DesignLibraryModel,
                ruleSetId?: FormatRulesetModel, templateType?: TemplateTypeModel) {
        this.name = name;
        this.tags = tags;
        this.masterPage = masterPage;
        this.library = libraryId;
        this.ruleSet = ruleSetId;
        this.templateType = templateType;
    }

    public isValid(publicationType: EPublicationType): boolean {
        switch (publicationType) {
            case EPublicationType.PRINT_MAGAZINE:
            case EPublicationType.WEB:
                return !!this.name && !!this.library && !!this.ruleSet;
            case EPublicationType.POS:
                return !!this.name && !!this.library && !!this.ruleSet && !!this.templateType;
            default:
                return false;
        }
    }
}

export class TemplateVariantPresetModel {
    public variant: VariantModel;
    public masterPage: MasterPageModel;
    public indesignLibrary: DesignLibraryModel;
    public formatRuleSet: FormatRulesetModel;

    public static fromFormValues(formValues: any): TemplateVariantPresetModel {
        return new TemplateVariantPresetModel(formValues?.variant, formValues?.masterPage, formValues?.library, formValues?.ruleSet);
    }

    constructor(variantId: VariantModel, masterPage?: MasterPageModel, libraryId?: DesignLibraryModel, ruleSetId?: FormatRulesetModel) {
        this.variant = variantId;
        if (masterPage) this.masterPage = masterPage;
        this.indesignLibrary = libraryId;
        this.formatRuleSet = ruleSetId;
    }

    public isValid(): boolean {
        return !!this.variant && !!this.indesignLibrary && !!this.formatRuleSet;
    }
}

export class TemplateVariantPresetBodyModel {
    public variant: string;
    public masterPage: string;
    public indesignLibrary: string;
    public indesignRuleSet: string;

    constructor(preset: TemplateVariantPresetModel) {
        this.variant = preset.variant._id;
        if (preset.masterPage) this.masterPage = preset.masterPage._id;
        this.indesignLibrary = preset.indesignLibrary._id;
        this.indesignRuleSet = preset.formatRuleSet._id;
    }
}
