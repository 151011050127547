import {Component, DestroyRef, inject, OnInit, signal, WritableSignal} from '@angular/core';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {RLValidatorConstants} from '../../../../classes/validators/rl-validators.constant';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA,
    RDModule
} from '@relayter/rubber-duck';
import {distinctUntilChanged, finalize, startWith} from 'rxjs/operators';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';
import {AsyncPipe, JsonPipe, NgTemplateOutlet} from '@angular/common';
import {DataFieldModel} from '../../../../models/api/data-field.model';
import {PipesModule} from '../../../../pipes/pipes.module';
import {ComponentsModule} from '../../../../components/components.module';
import {RulePropertyModel} from '../../../../models/api/rule-property.model';
import {EPropertyContext, PropertyService} from '../../../../api/services/property.service';
import {ARLogger, ARPagedResponseDataModel} from '@relayter/core';
import {Observable} from 'rxjs';
import {VariantService} from '../../../../api/services/variant.service';
import {VariantModel} from '../../../../models/api/variant.model';
import {Toaster} from '../../../../classes/toaster.class';
import {ConnectionApiService} from '../../../../api/services/connection.api.service';
import {EDataFieldCollectionName} from '../../../../app.enums';
import {
    ConnectionEndpointBody,
    ConnectionEndpointModel,
    EProducerAction,
    EWebhookProducerCollectionNames
} from '../../../../models/api/connection.model';

interface MappingFormModel {
    path: FormControl<string>;
    property: FormControl<string>
}

interface EndpointFormModel {
    model: FormControl<IDropdownItem>;
    action: FormControl<IDropdownItem>;
    path: FormControl<string>;
    field: FormControl<IDropdownItem>;
    mappings?: FormArray<FormGroup<MappingFormModel>>;
    masterBriefing?: FormControl<boolean>;
    campaignNamePath?: FormControl<string>;
    campaignDatePath?: FormControl<string>;
}

export interface IProducerWebhookEndpointFormComponentData {
    connectionId: string;
    endpointData?: ConnectionEndpointModel;
}

@Component({
    selector: 'producer-webhook-endpoint-form',
    templateUrl: './producer-webhook-endpoint-form.component.html',
    styleUrls: ['./producer-webhook-endpoint-form.component.scss'],
    standalone: true,
    providers: [ConnectionApiService],
    imports: [ReactiveFormsModule, RDModule, AsyncPipe, JsonPipe, NgTemplateOutlet, PipesModule, ComponentsModule]
})
export class ProducerWebhookEndpointFormComponent implements OnInit {
    private saveButton: ButtonConfig;
    private cancelButton: ButtonConfig;
    private endpoint: ConnectionEndpointModel;

    // Injections =================
    private destroyRef: DestroyRef = inject(DestroyRef);
    private fullModalService: FullModalService = inject(FullModalService);
    private connectionApiService: ConnectionApiService = inject(ConnectionApiService);
    private dataFieldDataService: DataFieldsApiService = inject(DataFieldsApiService);
    private propertyService: PropertyService = inject(PropertyService);
    private variantService: VariantService = inject(VariantService);
    private modalData: IProducerWebhookEndpointFormComponentData = inject<IProducerWebhookEndpointFormComponentData>(NUC_FULL_MODAL_DATA);

    public models: IDropdownItem[] = [new DropdownItem('Product', EWebhookProducerCollectionNames.PRODUCT),
        new DropdownItem('Briefing items', EWebhookProducerCollectionNames.CAMPAIGN_ITEM)];
    public actions: IDropdownItem[] = [new DropdownItem('Upsert', EProducerAction.UPSERT), new DropdownItem('Delete', EProducerAction.DELETE)];
    public form: FormGroup<EndpointFormModel>;
    public properties: WritableSignal<RulePropertyModel[]> = signal([]);
    public identifierFields: WritableSignal<DropdownItem<string>[]> = signal([]);
    public variants: VariantModel[] = [];

    // FORM getters ===============
    get mappings(): FormArray {
        return this.form.get('mappings') as FormArray;
    }

    get masterBriefing(): FormControl {
        return this.form.get('masterBriefing') as FormControl<boolean>;
    }

    public ngOnInit(): void {
        this.endpoint = this.modalData.endpointData;
        this.variantService.getVariants().pipe(
            takeUntilDestroyed(this.destroyRef)).subscribe({
            next: (result: ARPagedResponseDataModel<VariantModel>) => {
                this.variants = result.items;
            },
            error: (error) => Toaster.handleApiError(error)
        });

        this.initButtons();
        this.setupFormGroup();
    }

    private initButtons(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, this.endpoint?._id ? 'Save' : 'Create');
        this.cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        this.fullModalService.setConfirmClose(true);

        const cancel = new FullModalActionModel(this.cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false));

        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.saveEndpoint();
        });

        this.fullModalService.setModalActions([cancel, save]);
    }

    private setupFormGroup(): void {
        const currentModel = this.models.find(model =>
            model.getValue() === this.modalData.endpointData?.collectionName);
        const currentAction = this.actions.find(type => type.getValue() === this.modalData.endpointData?.action);

        this.form = new FormGroup<EndpointFormModel>({
            model: new FormControl(currentModel, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            path: new FormControl('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            field: new FormControl(null , RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            action: new FormControl(currentAction, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            mappings: new FormArray([])
        })
        this.form.controls.model.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (value: IDropdownItem) => {
                // reset mappings when collection is changed
                this.mappings?.clear();
                let propertiesObs: Observable<ARPagedResponseDataModel<RulePropertyModel>>;
                let identifierObs: Observable<DataFieldModel[]>;
                switch(value.getValue()) {
                    case EWebhookProducerCollectionNames.PRODUCT:
                        this.form.removeControl('masterBriefing')
                        this.form.removeControl('campaignNamePath');
                        this.form.removeControl('campaignDatePath');
                        identifierObs = this.dataFieldDataService.getAllDataFields(EDataFieldCollectionName.PRODUCT);
                        propertiesObs = this.propertyService.getProperties(EPropertyContext.WEBHOOK_PRODUCER_PRODUCT);
                        break;
                    case EWebhookProducerCollectionNames.CAMPAIGN_ITEM:
                        this.form.addControl('masterBriefing', new FormControl(false));
                        this.form.addControl('campaignNamePath', new FormControl('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED));
                        this.form.addControl('campaignDatePath', new FormControl('',RLValidatorConstants.VALIDATOR_SETS.REQUIRED));
                        this.masterBriefing.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
                            if (!value) {
                                // Set campaign specific import data
                                if (!this.form.controls.campaignNamePath) this.form.addControl('campaignNamePath',
                                    new FormControl('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED));
                                if (!this.form.controls.campaignDatePath) this.form.addControl('campaignDatePath',
                                    new FormControl('',RLValidatorConstants.VALIDATOR_SETS.REQUIRED));
                            } else {
                                // Remove campaign specific import data
                                this.form.removeControl('campaignNamePath');
                                this.form.removeControl('campaignDatePath');
                            }
                        });
                        identifierObs = this.dataFieldDataService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM);
                        propertiesObs = this.propertyService.getProperties(EPropertyContext.WEBHOOK_PRODUCER_CAMPAIGN_ITEM);
                        break;
                    default :
                        // show error message of for some reason the code reaches this point.
                        ARLogger.error('Collection name not supported');

                }
                identifierObs.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result: DataFieldModel[]) => {
                    this.identifierFields.set(result.map(item => new DropdownItem(item.name, item._id)));
                })
                propertiesObs.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result: ARPagedResponseDataModel<RulePropertyModel>) => {
                    this.properties.set(result.items);
                });
            }
        )
        this.form.statusChanges.pipe(
            distinctUntilChanged(),
            startWith(false),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status) => {
            this.saveButton.disabled = status !== 'VALID'; // disable the button based on the form status
        });
    }

    private saveEndpoint(): void {
        if (this.endpoint?._id) {
            // TODO update in future story
        } else { // create mode
            const event = ConnectionEndpointBody.fromFormGroup(this.form);
            this.connectionApiService.createEndpoint(this.modalData.connectionId, event)
                .pipe(finalize(() => this.saveButton.loading = false), takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => this.fullModalService.close(result),
                    error: (error) => Toaster.handleApiError(error)
                });
        }
    }

    public addMapping(): void {
        this.mappings.push(new FormGroup<MappingFormModel>({
            path: new FormControl<string>('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            property: new FormControl<string>('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
        }));
    }

    public deleteMapping(index: number): void {
        this.mappings.removeAt(index);
    }
}
