import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {
    ConnectionModel, ConnectionEndpointBody,
} from '../../models/api/connection.model';
import {ApiService} from './api.service';
import {ApiPostRequest} from '../requests/api-post.request';

@Injectable()
export class ConnectionApiService extends ApiService<ConnectionModel> {

    constructor() {
        super([ApiConstants.API_GROUP_CONNECTIONS], ConnectionModel);
    }
    public createEndpoint(connectionId: string, event: ConnectionEndpointBody): Observable<ConnectionModel> {
        const request = new ApiPostRequest(
            [...this.groups, connectionId, ApiConstants.API_METHOD_ENDPOINT], ConnectionModel, event);

        return this.apiRequestService.post(request);
    }
}
