import {Injectable} from '@angular/core';
import {EUploadStatus, UploadFileStatus} from './components/upload-file-component/upload.model';
import {ITableAction} from '@relayter/rubber-duck/lib/modules/table/components/table/table-config';

export interface IJob {
    name: string,
    title: string,
    download?: boolean
}

@Injectable()
export class AppConstants {
    public static readonly LOCALSTORAGE_LOCALE: string = 'relayter.locale';
    public static readonly MAIN_REDIRECT: string = '/';
    public static readonly AUTH_REDIRECT: string = '/auth';
    public static readonly LOGIN_REDIRECT: string = '/auth/login';
    public static readonly PATH_PREFIX: string = '/';
    public static readonly PRODUCTS_PATH: string = '/products';
    public static readonly ASSETS_PATH: string = '/assets';
    public static readonly ASSETS_IMAGES_PATH: string = '/assets/images';
    public static readonly CAMPAIGN_PATH: string = '/campaigns';
    public static readonly ROLES_PATH: string = '/settings/role-management';
    public static readonly USERS_PATH: string = '/settings/user-management';
    public static readonly CONNECTION_PATH: string = '/settings/team-setup/connections';
    public static readonly CALLBACK_REDIRECT: string = '/auth/callback';
    public static readonly HOME_PAGE: string = AppConstants.ASSETS_PATH;
    public static readonly WORKFLOW_AUTOMATION_PATH: string = '/workflow/workflow-automations';
    public static readonly PACKAGE_SETUPS_PATH: string = '/workflows/package-setups';
    public static readonly DELIVERY_UNAUTHORIZED: string = '/delivery/unauthorized';
    public static readonly DELIVERY_AUTH_TOKEN_EXPIRED: string = '/delivery/auth/token-expired';
    public static readonly OBJECT_ID_REGEX = '[0-9a-fA-F]{24}';
    public static readonly POPOUT_Y_OFFSET = 6;
    public static readonly DEFAULT_TIMEZONE = 'Europe/Amsterdam';

    public static readonly PERMISSIONS = {
        POST_INVITE: 'POST_INVITE',
        GET_INVITES: 'GET_INVITES',
        DELETE_INVITE: 'DELETE_INVITE',
        GET_USERS: 'GET_USERS',
        GET_PRODUCTS: 'GET_PRODUCTS',
        GET_PRODUCT: 'GET_PRODUCT',
        GET_ROLE: 'GET_ROLE',
        GET_ROLE_USERS: 'GET_ROLE_USERS',
        GET_ROLES: 'GET_ROLES',
        POST_ROLE: 'POST_ROLE',
        GET_OWN_USER_PROFILE: 'GET_OWN_USER_PROFILE',
        CHANGE_OWN_PASSWORD: 'CHANGE_OWN_PASSWORD',
        PATCH_USER: 'PATCH_USER',
        GET_USER_DETAILS: 'GET_USER_DETAILS',
        DELETE_ROLE: 'DELETE_ROLE',
        DELETE_USER: 'DELETE_USER',
        POST_PRODUCTS: 'POST_PRODUCTS',
        POST_PRODUCT_JOB: 'POST_PRODUCT_JOB',
        GET_ASSETS: 'GET_ASSETS',
        GET_ASSET_DETAILS: 'GET_ASSET_DETAILS',
        POST_ASSETS: 'POST_ASSETS', // for web only, we post jobs to create assets instead of a post call
        POST_TAGS: 'POST_TAGS',
        GET_TAGS: 'GET_TAGS',
        POST_BRANDS: 'POST_BRANDS',
        GET_BRANDS: 'GET_BRANDS',
        PATCH_PRODUCT: 'PATCH_PRODUCT',
        DELETE_PRODUCT: 'DELETE_PRODUCT',
        GET_CHANNELS: 'GET_CHANNELS',
        POST_CAMPAIGN: 'POST_CAMPAIGN',
        GET_CAMPAIGNS: 'GET_CAMPAIGNS',
        GET_CAMPAIGN: 'GET_CAMPAIGN',
        DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
        POST_CAMPAIGN_ITEM: 'POST_CAMPAIGN_ITEM',
        GET_CAMPAIGN_ITEMS: 'GET_CAMPAIGN_ITEMS',
        POST_CAMPAIGN_PUBLICATION: 'POST_CAMPAIGN_PUBLICATION',
        GET_CAMPAIGN_PUBLICATIONS: 'GET_CAMPAIGN_PUBLICATIONS',
        DELETE_CAMPAIGN_ITEM: 'DELETE_CAMPAIGN_ITEM',
        GET_CAMPAIGN_PUBLICATIONS_DETAILS: 'GET_CAMPAIGN_PUBLICATIONS_DETAILS',
        GET_TEMPLATES: 'GET_TEMPLATES',
        PUT_ROLE: 'PUT_ROLE',
        GET_CAMPAIGN_ITEM: 'GET_CAMPAIGN_ITEM',
        POST_TEMPLATE: 'POST_TEMPLATE',
        GET_PENDING_EXPORTS: 'GET_PENDING_EXPORTS',
        PUT_TEMPLATE: 'PUT_TEMPLATE',
        GET_TEMPLATE: 'GET_TEMPLATE',
        GET_TEMPLATES_USAGE: 'GET_TEMPLATES_USAGE',
        DELETE_TEMPLATE: 'DELETE_TEMPLATE',
        PUT_CAMPAIGN_ITEM: 'PUT_CAMPAIGN_ITEM',
        POST_STICKY_NOTE: 'POST_STICKY_NOTE',
        GET_STICKY_NOTE: 'GET_STICKY_NOTE',
        PUT_STICKY_NOTE: 'PUT_STICKY_NOTE',
        GET_STICKY_NOTES_LOG: 'GET_STICKY_NOTES_LOG',
        GET_STICKY_NOTE_COMMENTS: 'GET_STICKY_NOTE_COMMENTS',
        GET_TEMPLATE_TYPES: 'GET_TEMPLATE_TYPES',
        POST_TEMPLATE_TYPES: 'POST_TEMPLATE_TYPES',
        POST_POS: 'POST_POS',
        GET_DESIGN_LIBRARIES: 'GET_DESIGN_LIBRARIES',
        POST_DESIGN_LIBRARY_JOBS: 'POST_DESIGN_LIBRARY_JOBS',
        DELETE_DESIGN_LIBRARY: 'DELETE_DESIGN_LIBRARY',
        GET_PUBLICATION_ITEMS: 'GET_PUBLICATION_ITEMS',
        DELETE_PUBLICATION_ITEMS: 'DELETE_PUBLICATION_ITEMS',
        GET_PUBLICATION_POS_TYPE_STATUS: 'GET_PUBLICATION_POS_TYPE_STATUS',
        GET_PUBLICATION_POS_TYPE_PDFS: 'GET_PUBLICATION_POS_TYPE_PDFS',
        GET_CAMPAIGN_ITEM_EXTERNAL_ID: 'GET_CAMPAIGN_ITEM_EXTERNAL_ID',
        PUT_ASSET_DETAILS: 'PUT_ASSET_DETAILS',
        PUT_SPREAD: 'PUT_SPREAD',
        PUT_SPREAD_CONTENT: 'PUT_SPREAD_CONTENT',
        PUT_CAMPAIGN: 'PUT_CAMPAIGN',
        SWAP_SPREADS: 'SWAP_SPREADS',
        UPDATE_CAMPAIGN_PUBLICATION: 'UPDATE_CAMPAIGN_PUBLICATION',
        DELETE_CAMPAIGN_PUBLICATION: 'DELETE_CAMPAIGN_PUBLICATION',
        COPY_CAMPAIGN: 'COPY_CAMPAIGN',
        LINK_ASSETS_TO_PRODUCTS: 'LINK_ASSETS_TO_PRODUCTS',
        DELETE_ASSET: 'DELETE_ASSET',
        GET_ASSET_USAGE_IN_CAMPAIGNS: 'GET_ASSET_USAGE_IN_CAMPAIGNS',
        GET_TEAM_DETAILS: 'GET_TEAM_DETAILS',
        POST_MASTER_PAGE_JOBS: 'POST_MASTER_PAGE_JOBS',
        GET_MASTER_PAGES: 'GET_MASTER_PAGES',
        PATCH_MASTER_PAGES: 'PATCH_MASTER_PAGES',
        GET_MASTER_PAGES_USAGE: 'GET_MASTER_PAGES_USAGE',
        DELETE_MASTER_PAGE: 'DELETE_MASTER_PAGE',
        POST_GROUP: 'POST_GROUP',
        UPDATE_GROUPS: 'UPDATE_GROUPS',
        POST_CONTENT_RULE: 'POST_CONTENT_RULE',
        UPDATE_CONTENT_RULE: 'UPDATE_CONTENT_RULE',
        POST_GROUP_JOBS: 'POST_GROUP_JOBS',
        POST_CONTENT_RULE_JOBS: 'POST_CONTENT_RULE_JOBS',
        POST_DATA_FIELD: 'POST_DATA_FIELD',
        PATCH_DATA_FIELD: 'PATCH_DATA_FIELD',
        DELETE_DATA_FIELD: 'DELETE_DATA_FIELD',
        POST_ASSET_JOB: 'POST_ASSET_JOB',
        POST_FORM: 'POST_FORM',
        PUT_FORM: 'PUT_FORM',
        PATCH_DESIGN_LIBRARY: 'PATCH_DESIGN_LIBRARY',
        POST_PACKAGE_SETUP: 'POST_PACKAGE_SETUP',
        GET_PACKAGE_SETUPS: 'GET_PACKAGE_SETUPS',
        PATCH_PACKAGE_SETUP: 'PATCH_PACKAGE_SETUP',
        POST_PACKAGE_SETUP_RULE: 'POST_PACKAGE_SETUP_RULE',
        PUT_PACKAGE_SETUP_RULE: 'PUT_PACKAGE_SETUP_RULE',
        DELETE_PACKAGE_SETUP_RULE: 'DELETE_PACKAGE_SETUP_RULE',
        DELETE_PACKAGE_SETUP: 'DELETE_PACKAGE_SETUP',
        GET_PACKAGES: 'GET_PACKAGES',
        POST_PACKAGE_SETUP_JOB: 'POST_PACKAGE_SETUP_JOB',
        COPY_PACKAGE_SETUP_RULE: 'COPY_PACKAGE_SETUP_RULE',
        COPY_PACKAGE_SETUP: 'COPY_PACKAGE_SETUP',
        POST_CAMPAIGN_JOB: 'POST_CAMPAIGN_JOB',
        PATCH_PACKAGES: 'PATCH_PACKAGES',
        PUT_PACKAGES_ITEMS: 'PUT_PACKAGES_ITEMS',
        GET_PACKAGE_DETAILS: 'GET_PACKAGE_DETAILS',
        GET_STATISTICS: 'GET_STATISTICS',
        POST_WORKFLOW_AUTOMATION: 'POST_WORKFLOW_AUTOMATION',
        GET_WORKFLOW_AUTOMATION_DETAILS: 'GET_WORKFLOW_AUTOMATION_DETAILS',
        GET_WORKFLOW_AUTOMATION_RULES: 'GET_WORKFLOW_AUTOMATION_RULES',
        PATCH_WORKFLOW_AUTOMATION_RULE: 'PATCH_WORKFLOW_AUTOMATION_RULE',
        DELETE_WORKFLOW_AUTOMATION_RULE: 'DELETE_WORKFLOW_AUTOMATION_RULE',
        POST_WORKFLOW_AUTOMATION_RULES: 'POST_WORKFLOW_AUTOMATION_RULES',
        GET_WORKFLOW_AUTOMATIONS: 'GET_WORKFLOW_AUTOMATIONS',
        PATCH_WORKFLOW_AUTOMATION: 'PATCH_WORKFLOW_AUTOMATION',
        DELETE_WORKFLOW_AUTOMATION: 'DELETE_WORKFLOW_AUTOMATION',
        COPY_WORKFLOW_AUTOMATION_RULE: 'COPY_WORKFLOW_AUTOMATION_RULE',
        COPY_WORKFLOW_AUTOMATION: 'COPY_WORKFLOW_AUTOMATION',
        POST_WORKFLOW_AUTOMATION_JOB: 'POST_WORKFLOW_AUTOMATION_JOB',
        DELETE_PACKAGE: 'DELETE_PACKAGE',
        ORDER_DATA_FIELDS: 'ORDER_DATA_FIELDS',
        DELETE_PACKAGE_ITEM: 'DELETE_PACKAGE_ITEM',
        POST_PUBLICATION_JOB: 'POST_PUBLICATION_JOB',
        GET_CONNECTIONS: 'GET_CONNECTIONS',
        DELETE_CONNECTION: 'DELETE_CONNECTION',
        POST_CONNECTION: 'POST_CONNECTION',
        PATCH_CONNECTION: 'PATCH_CONNECTION',
        REFRESH_ACCESS_TOKEN: 'REFRESH_ACCESS_TOKEN',
        GET_VARIANTS: 'GET_VARIANTS',
        POST_VARIANT: 'POST_VARIANT',
        PATCH_VARIANT: 'PATCH_VARIANT',
        GET_FORMAT_RULESETS: 'GET_FORMAT_RULESETS',
        GET_FORMAT_RULESET: 'GET_FORMAT_RULESET',
        POST_FORMAT_RULESET: 'POST_FORMAT_RULESET',
        COPY_FORMAT_RULESET: 'COPY_FORMAT_RULESET',
        POST_FORMAT_RULESET_JOBS: 'POST_FORMAT_RULESET_JOBS',
        DELETE_FORMAT_RULESET: 'DELETE_FORMAT_RULESET',
        PUT_FORMAT_RULESET: 'PUT_FORMAT_RULESET',
        GET_FORMAT_RULESET_ITEMS: 'GET_FORMAT_RULESET_ITEMS',
        GET_FORMAT_RULESET_ASSET_ITEMS: 'GET_FORMAT_RULESET_ASSET_ITEMS',
        GET_FORMAT_RULESET_ITEM: 'GET_FORMAT_RULESET_ITEM',
        GET_FORMAT_RULESET_ASSET_ITEM: 'GET_FORMAT_RULESET_ASSET_ITEM',
        PUT_FORMAT_RULESET_ITEM: 'PUT_FORMAT_RULESET_ITEM',
        PUT_FORMAT_RULESET_ASSET_ITEM: 'PUT_FORMAT_RULESET_ASSET_ITEM',
        DELETE_FORMAT_RULESET_ITEM: 'DELETE_FORMAT_RULESET_ITEM',
        DELETE_FORMAT_RULESET_ASSET_ITEM: 'DELETE_FORMAT_RULESET_ASSET_ITEM',
        POST_FORMAT_RULESET_ITEM: 'POST_FORMAT_RULESET_ITEM',
        POST_FORMAT_RULESET_ASSET_ITEM: 'POST_FORMAT_RULESET_ASSET_ITEM',
        COPY_FORMAT_RULESET_ITEM: 'COPY_FORMAT_RULESET_ITEM',
        COPY_FORMAT_RULESET_ASSET_ITEM: 'COPY_FORMAT_RULESET_ASSET_ITEM',
        GET_FORMAT_RULESET_ITEM_GROUPS: 'GET_FORMAT_RULESET_ITEM_GROUPS',
        GET_FORMAT_RULESET_ITEM_GROUP: 'GET_FORMAT_RULESET_ITEM_GROUP',
        PUT_FORMAT_RULESET_ITEM_GROUP: 'PUT_FORMAT_RULESET_ITEM_GROUP',
        DELETE_FORMAT_RULESET_ITEM_GROUP: 'DELETE_FORMAT_RULESET_ITEM_GROUP',
        POST_FORMAT_RULESET_ITEM_GROUP: 'POST_FORMAT_RULESET_ITEM_GROUP',
        COPY_FORMAT_RULESET_ITEM_GROUP: 'COPY_FORMAT_RULESET_ITEM_GROUP',
        GET_FORMAT_RULESET_USAGE: 'GET_FORMAT_RULESET_USAGE',
        GET_PACKAGE_SETUP_RULES: 'GET_PACKAGE_SETUP_RULES',
        GET_PERMISSIONS: 'GET_PERMISSIONS',
        GET_GROUPS: 'GET_GROUPS',
        GET_CONTENT_RULES: 'GET_CONTENT_RULES',
        EXPORT_ASSETS_JOB: 'EXPORT_ASSETS_JOB',
        GET_WORKFLOW_CONFIGURATIONS: 'GET_WORKFLOW_CONFIGURATIONS',
        GET_WORKFLOW_CONFIGURATION: 'GET_WORKFLOW_CONFIGURATION',
        POST_WORKFLOW_CONFIGURATION: 'POST_WORKFLOW_CONFIGURATION',
        PATCH_WORKFLOW_CONFIGURATION: 'PATCH_WORKFLOW_CONFIGURATION',
        DELETE_WORKFLOW_CONFIGURATION: 'DELETE_WORKFLOW_CONFIGURATION',
        POST_WORKFLOW_CONFIGURATION_JOB: 'POST_WORKFLOW_CONFIGURATION_JOB',
        POST_MASTER_BRIEFING_JOB: 'POST_MASTER_BRIEFING_JOB',
        POST_PRODUCT_ASSET_EXPORT_SETUP: 'POST_PRODUCT_ASSET_EXPORT_SETUP',
        GET_PRODUCT_ASSET_EXPORT_SETUPS: 'GET_PRODUCT_ASSET_EXPORT_SETUPS',
        GET_PRODUCT_ASSET_EXPORT_SETUP: 'GET_PRODUCT_ASSET_EXPORT_SETUP',
        POST_PRODUCT_ASSET_EXPORT_SETUP_JOBS: 'POST_PRODUCT_ASSET_EXPORT_SETUP_JOBS',
        POST_TEMPLATE_JOBS: 'POST_TEMPLATE_JOBS',
        GET_USAGES: 'GET_USAGES',
        GET_AUDIT_TRAIL: 'GET_AUDIT_TRAIL',
        POST_AFTER_EFFECTS_PROJECT_FILE_JOBS: 'POST_AFTER_EFFECTS_PROJECT_FILE_JOBS',
        PATCH_AFTER_EFFECTS_PROJECT_FILE: 'PATCH_AFTER_EFFECTS_PROJECT_FILE',
        GET_AFTER_EFFECTS_PROJECT_FILES: 'GET_AFTER_EFFECTS_PROJECT_FILES',
        GET_AFTER_EFFECTS_PROJECT_FILE: 'GET_AFTER_EFFECTS_PROJECT_FILE',
        POST_WEBHOOK_ENDPOINT: 'POST_WEBHOOK_ENDPOINT',
        WORKFLOW_1: 'WORKFLOW_1',
        WORKFLOW_2: 'WORKFLOW_2',
        WORKFLOW_3: 'WORKFLOW_3',
        WORKFLOW_4: 'WORKFLOW_4',
        WORKFLOW_5: 'WORKFLOW_5',
        WORKFLOW_6: 'WORKFLOW_6',
        WORKFLOW_7: 'WORKFLOW_7',
        WORKFLOW_8: 'WORKFLOW_8',
        WORKFLOW_9: 'WORKFLOW_9',
        WORKFLOW_10: 'WORKFLOW_10'
    };

    public static readonly CONTEXT_URL = {
        CAMPAIGNS: 'campaigns',
        PUBLICATIONS: 'publications',
        PRODUCTS: 'products',
        ASSETS: 'assets',
        TEMPLATES: 'templates',
        PRINT_MAGAZINE: 'print-magazine',
        SETTINGS: 'settings',
        USER_MANAGEMENT: 'user-management',
        ROLE_MANAGEMENT: 'role-management',
        PERMISSIONS: 'permissions',
        USERS: 'users',
        BRIEFING: 'briefing',
        POS_BRIEFING: 'pos_briefing',
        DESIGN_LIBRARIES: 'design-libraries',
        MASTER_PAGES: 'master-pages',
        TEAM_SETUP: 'team-setup',
        GENERAL_INFORMATION: 'general-information',
        BRIEFING_DATA_MANAGEMENT: 'briefing-data-management',
        DATA_FIELDS: 'data-fields',
        BRIEFING_FORM: 'briefing-form',
        PRODUCT_DATA_MANAGEMENT: 'product-data-management',
        PRODUCT_FORM: 'product-form',
        PENDING_INVITES: 'pending-invites',
        USERS_OVERVIEW: 'users-overview',
        WORKFLOWS: 'workflows',
        CONFIGURATIONS: 'configurations',
        STEPS: 'steps',
        COMPONENTS: 'components',
        CAMPAIGN_PACKAGE_SETUPS: 'campaign-package-setups',
        PACKAGES: 'packages',
        WORKFLOW_AUTOMATIONS: 'workflow-automations',
        ASSET_DATA_MANAGEMENT: 'asset-data-management',
        ASSET_FORM: 'asset-form',
        CONNECTIONS: 'connections',
        VARIANTS: 'variants',
        FORMAT_RULESETS: 'format-rulesets',
        CONTENT_GROUPS: 'content-groups',
        CONTENT_RULES: 'content-rules',
        MASTER_BRIEFING: 'master-briefing',
        EXPORT_SETUPS: 'export-setups',
        PRODUCT_ASSET_EXPORT_SETUPS: 'product-asset-export-setups',
        AUDIT_TRAIL:'audit-trail',
        AFTER_EFFECTS_PROJECT_FILES: 'after-effects-project-files',
        VIDEO: 'video',
        COMPOSITIONS: 'compositions',
        LAYERS: 'layers',
        PREVIEW: 'preview'
    };

    public static readonly TABLE_ACTION_TYPES: Record<string, ITableAction> = {
        EDIT: {title: 'Edit', icon: 'nucicon_edit'},
        DELETE: {title: 'Delete', icon: 'nucicon_trash_fill'},
        ADD: {title: 'Add', icon: 'nucicon_add'},
        COPY: {title: 'Copy', icon: 'nucicon_duplicate'},
        DOWNLOAD: {title: 'Download', icon: 'nucicon_download'},
        MOVE_UP: {title: 'Move up', icon: 'nucicon_chevron_up'},
        MOVE_DOWN: {title: 'Move down', icon: 'nucicon_chevron_down'},
        VIEW: {title: 'View', icon: 'nucicon_sub_information'}
    };

    public static readonly TABLE_DISPLAY_TYPES = {
        DEFAULT: 'DEFAULT',
        SWITCH: 'SWITCH',
        CURRENCY: 'CURRENCY',
        CHECKBOX: 'CHECKBOX',
        CHECKBOX_DISABLED: 'CHECKBOX_DISABLED',
        BOOLEAN: 'BOOLEAN',
        LABEL_WITH_TOOLTIP: 'LABEL_WITH_TOOLTIP',
        DATE_DETAILED: 'DATE_DETAILED',
        DATE_DEFAULT: 'DATE_DEFAULT',
        THUMB: 'THUMB'
    };

    public static readonly PAGE_INDEX_DEFAULT: number = 1;
    public static readonly PAGE_SIZE_DEFAULT: number = 20;
    public static readonly PAGE_SIZE_MAX: number = 100;
    public static readonly PAGE_SIZE_DIALOG: number = 5;
    public static readonly PAGE_SIZE_OPTIONS: number[] = [AppConstants.PAGE_SIZE_DEFAULT, 60, AppConstants.PAGE_SIZE_MAX];
    public static readonly QUERY_PARAM_RETURN_URL: string = 'returnUrl';

    public static readonly INDESIGN_DOCUMENT_BOUNDARIES = {
        MINIMUM_HEIGHT_AND_WIDTH: 26 // Millimeters
    };

    public static readonly CAMPAIGN_STATUS = {
        NEW: 'NEW',
        BRIEFING: 'BRIEFING',
        PRODUCTION: 'PRODUCTION',
        READY: 'READY',
        DONE: 'DONE'
    };

    public static readonly AMAZON_AWS_REQUEST: string = 'amazonaws';

    public static readonly FIRST_BRAND_COLOR: string = '#3FBB87';
    public static readonly FIFTH_BRAND_COLOR: string = '#e94954';
    public static readonly VARIANT_ICON_INFO_COLOR: string = '#4c4c4c';

    public static readonly UPLOADED_FILE_STATUS: Record<string, UploadFileStatus> = {
        DONE: {
            name: 'DONE',
            description: 'Upload complete',
            icon: 'nucicon_check_round_fill',
            iconColor: AppConstants.FIRST_BRAND_COLOR,
            status: EUploadStatus.Done
        },
        UPLOADING: {
            name: 'UPLOADING',
            description: 'Uploading file',
            icon: 'nucicon_check_round_fill',
            iconColor: AppConstants.FIRST_BRAND_COLOR,
            status: EUploadStatus.Uploading
        },
        ERROR: {
            name: 'ERROR',
            description: 'Upload failed',
            icon: 'nucicon_warning_fill',
            iconColor: AppConstants.FIFTH_BRAND_COLOR,
            status: EUploadStatus.Failed
        }
    };

    public static readonly PUBLICATION_ITEM_CONTENT_TYPES = {
        CAMPAIGN_ITEM: 'CAMPAIGN_ITEM',
        LAYOUT_NOTE: 'LAYOUT_NOTE',
        ASSET: 'ASSET'
    };

    public static readonly DEEPLINK_ACTIONS = {
        STICKYLOG_COMMENTS: 'stickylog-comments',
        WORKFLOW_PUBLICATION_ITEM: 'workflow-publication-item'
    };

    public static readonly STICKY_NOTE_TOOLTIPS = {
        EDIT: 'Go to linked briefing item', // temporary solution, users can also view briefing item if they have no PUT_CAMPAIGN_ITEM permission
        REMOVE: 'Remove linked briefing item',
        LINK: 'Link new briefing item',
        DELETE: 'Delete note',
        DUPLICATE: 'Mark as duplicate'
    };

    public static readonly JOBS: Record<string, IJob> = {
        PACKAGE_GENERATION: {name: 'PACKAGE_GENERATION', title: 'Package Generation', download: true},
        ARRANGE_PAGES: {name: 'ARRANGE_PAGES', title: 'Arrange Pages'},
        CREATE_INDESIGN_LIBRARY: {name: 'CREATE_INDESIGN_LIBRARY', title: 'Add InDesign Library'},
        UPDATE_INDESIGN_LIBRARY_JOB: {name: 'UPDATE_INDESIGN_LIBRARY_JOB', title: 'Update InDesign Library'},
        COPY_PUBLICATION: {name: 'COPY_PUBLICATION', title: 'Copy Publication'},
        ADD_MASTER_PAGE: {name: 'ADD_MASTER_PAGE', title: 'Add master page'},
        UPDATE_MASTER_PAGE: {name: 'UPDATE_MASTER_PAGE', title: 'Update master page'},
        APPLY_CONTENT_RULES: {name: 'APPLY_CONTENT_RULES', title: 'Apply content rules'},
        CAMPAIGN_IMPORT_JOB: {name: 'CAMPAIGN_IMPORT_JOB', title: 'Campaign import'},
        PRODUCT_IMPORT: {name: 'PRODUCT_IMPORT', title: 'Product import'},
        CAMPAIGN_EXPORT_BRIEFING_JOB: {name: 'CAMPAIGN_EXPORT_BRIEFING_JOB', title: 'Campaign briefing items export', download: true},
        EXPORT_IMAGE_JOB: {name: 'EXPORT_IMAGE_JOB', title: 'Export image', download: true},
        ADD_DEFAULT_ASSETS_NEW: {name: 'ADD_DEFAULT_ASSETS_NEW', title: 'Add assets'},
        CAMPAIGN_COPY_JOB: {name: 'CAMPAIGN_COPY_JOB', title: 'Campaign copy'},
        CAMPAIGN_DELETE_JOB: {name: 'CAMPAIGN_DELETE_JOB', title: 'Campaign delete'},
        PUBLICATION_DELETE_JOB: {name: 'PUBLICATION_DELETE_JOB', title: 'Campaign publication delete'},
        DELETE_DATA_FIELD_JOB: {name: 'DELETE_DATA_FIELD_JOB', title: 'Delete data field'},
        CAMPAIGN_ADD_PACKAGES_JOB: {name: 'CAMPAIGN_ADD_PACKAGES_JOB', title: 'Add campaign packages'},
        CAMPAIGN_UPDATE_PACKAGE_JOB: {name: 'CAMPAIGN_UPDATE_PACKAGE_JOB', title: 'Update campaign package'},
        CAMPAIGN_UPDATE_ALL_PACKAGES_JOB: {name: 'CAMPAIGN_UPDATE_ALL_PACKAGES_JOB', title: 'Update all campaign packages'},
        CAMPAIGN_PACKAGE_EXPORT_JOB: {name: 'CAMPAIGN_PACKAGE_EXPORT_JOB', title: 'Package export', download: true},
        DELETE_GROUPS: {name: 'DELETE_GROUPS', title: 'Delete content group'},
        RENAME_DATA_FIELD_NAME_JOB: {name: 'RENAME_DATA_FIELD_NAME_JOB', title: 'Rename the name of a data field'},
        NOTE_EXPORT_JOB: {name: 'NOTE_EXPORT_JOB', title: 'Notes export', download: true},
        WORKFLOW_AUTOMATION_RULE_COPY_JOB: {name: 'WORKFLOW_AUTOMATION_RULE_COPY_JOB', title: 'Duplicate workflow automation rule'},
        WORKFLOW_AUTOMATION_COPY_JOB: {name: 'WORKFLOW_AUTOMATION_COPY_JOB', title: 'Duplicate workflow automation'},
        PACKAGE_SETUP_RULE_COPY_JOB: {name: 'PACKAGE_SETUP_RULE_COPY_JOB', title: 'Duplicate package rule'},
        PACKAGE_SETUP_COPY_JOB: {name: 'PACKAGE_SETUP_COPY_JOB', title: 'Duplicate package setup'},
        FORMAT_RULESET_COPY_JOB: {name: 'FORMAT_RULESET_COPY_JOB', title: 'Duplicate format ruleset'},
        ENABLE_VARIANT_DATA_FIELD_JOB: {name: 'ENABLE_VARIANT_DATA_FIELD_JOB', title: 'Enable variant for data field'},
        EXPORT_ASSETS_JOB: {name: 'EXPORT_ASSETS_JOB', title: 'Export assets to one or more archive files'},
        DELETE_WORKFLOW_CONFIGURATION_STEP_JOB: {name: 'DELETE_WORKFLOW_CONFIGURATION_STEP_JOB', title: 'Delete workflow configuration step'},
        COPY_MASTER_BRIEFING_TO_CAMPAIGN_JOB: {name: 'COPY_MASTER_BRIEFING_TO_CAMPAIGN_JOB', title: 'Import from master briefing'},
        COPY_WORKFLOW_CONFIGURATION_JOB: {name: 'COPY_WORKFLOW_CONFIGURATION_JOB', title: 'Copy workflow configuration'},
        PRODUCT_EXPORT_JOB: {name: 'PRODUCT_EXPORT_JOB', title: 'Product export', download: true},
        EDIT_PRODUCT_ASSET_EXPORT_SETUP_JOB: {name: 'EDIT_PRODUCT_ASSET_EXPORT_SETUP_JOB', title: 'Edit product asset export setup'},
        DELETE_PRODUCT_ASSET_EXPORT_SETUP_JOB: {
            name: 'DELETE_PRODUCT_ASSET_EXPORT_SETUP_JOB',
            title: 'Delete product asset export setup'
        },
        DELETE_TEMPLATE_JOB: {name: 'DELETE_TEMPLATE_JOB', title: 'Delete template'},
        ADD_AFTER_EFFECTS_PROJECT_FILE_JOB: {name: 'ADD_AFTER_EFFECTS_PROJECT_FILE_JOB', title: 'Add After Effects project file'},
        UPDATE_AFTER_EFFECTS_PROJECT_FILE_JOB: {
            name: 'UPDATE_AFTER_EFFECTS_PROJECT_FILE_JOB',
            title: 'Update After Effects project file'
        }
    };

    public static readonly ICONS = {
        ASC: 'nucicon_sort_asc',
        DESC: 'nucicon_sort_dsc',
        IDML: '/assets/images/icon_IDML.svg',
        IMAGE_MAIN: 'assets/images/icon_image_main.svg',
        PDF: '/assets/images/icon_PDF.svg',
        PNG: '/assets/images/icon_PNG.svg',
        CUSTOM: '/assets/images/icon_custom.svg',
        VARIANT: 'nucicon_variant',
        FULLSCREEN: 'nucicon_fullscreen',
        COLLAPSE: 'nucicon_collapse',
        AFTER_EFFECTS_AUDIO: 'assets/images/icon_after_effects_audio.svg',
        AFTER_EFFECTS_IMAGE: 'assets/images/icon_after_effects_image.svg',
        AFTER_EFFECTS_SOLID: 'assets/images/icon_after_effects_solid.svg',
        AFTER_EFFECTS_TEXT: 'assets/images/icon_after_effects_text.svg',
        AFTER_EFFECTS_3D: 'assets/images/icon_after_effects_3d.svg',
        AFTER_EFFECTS_AV: 'assets/images/icon_after_effects_av.svg',
        AFTER_EFFECTS_CAMERA: 'assets/images/icon_after_effects_camera.svg',
        AFTER_EFFECTS_FILE: 'assets/images/icon_after_effects_file.svg',
        AFTER_EFFECTS_FOOTAGE: 'assets/images/icon_after_effects_footage.svg',
        AFTER_EFFECTS_LIGHT: 'assets/images/icon_after_effects_light.svg',
        AFTER_EFFECTS_PLACEHOLDER: 'assets/images/icon_after_effects_placeholder.svg',
        AFTER_EFFECTS_SHAPE: 'assets/images/icon_after_effects_shape.svg'
    };

    public static readonly RULESET_OPERATORS = {
        OR: 'OR',
        AND: 'AND'
    };
}
