import {
    FormatRulesetItemFormComponent,
    IFormatRulesetItemFormComponentData
} from '../format-ruleset-item-form/format-ruleset-item-form.component';
import {DesignLibraryModel} from '../models/api/design-library.model';
import {DesignLibraryService} from '../../../api/services/design-library.service';
import {FormatRulesetModel} from '../models/api/format-ruleset.model';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FullModalConfig, FullModalService, ITableAction, ITableColumn} from '@relayter/rubber-duck';
import {FormatRulesetItemGroupModel} from '../models/api/format-ruleset-item-group.model';
import {FormatRulesetBaseItem, FormatRulesetItemModel} from '../models/api/format-ruleset-item.model';
import {FormatRulesetService} from '../../../api/services/format-ruleset.service';
import {ActivatedRoute} from '@angular/router';
import {SortDirection} from '@angular/material/sort';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {
    FormatRulesetAssetItemTableComponent
} from './format-ruleset-item-table/format-ruleset-asset-item-table/format-ruleset-asset-item-table.component';
import {FormatRulesetAssetItemModel} from '../models/api/format-ruleset-asset-item.model';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {Observable, Subscription} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {AppConstants} from '../../../app.constants';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {EPropertyContext, PropertyService} from '../../../api/services/property.service';
import {FormatRulesetItemTableComponent} from './format-ruleset-item-table/format-ruleset-item-table.component';
import {TabBarItemModel} from '../../../models/ui/tab-bar-item.model';
import {Toaster} from '../../../classes/toaster.class';
import {TabBarRoutableItem} from '../../../pages/relayter/team-settings/tab-bar-routable-item.model';
import {ERulesetContext} from '../rule-form/base-ruleset-item-form.component';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {DesignLibraryItemModel} from '../models/api/design-library-item.model';
import {
    FormatRulesetItemGroupFormComponent,
    IFormatRulesetItemGroupFormComponentData
} from '../format-ruleset-item-group-form/format-ruleset-item-group-form.component';
import {
    FormatRulesetItemGroupTableComponent
} from './format-ruleset-item-table/format-ruleset-item-group-table/format-ruleset-item-group-table.component';
import {
    FormatRulesetAssetItemFormComponent,
    IFormatRulesetAssetItemFormComponentData
} from '../format-ruleset-asset-item-form/format-ruleset-asset-item-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'format-rule-set-detail',
    templateUrl: './format-ruleset-detail.component.html',
    styleUrls: ['./format-ruleset-detail.component.scss'],
    providers: [PaginatorService]
})
export class FormatRulesetDetailComponent extends RLTableComponent implements OnInit {
    @ViewChild('rulesetItemTable') public rulesetItemTable: FormatRulesetItemTableComponent;
    @ViewChild('rulesetAssetItemTable') public rulesetAssetItemTable: FormatRulesetAssetItemTableComponent;
    @ViewChild('rulesetItemGroupTable') public rulesetItemGroupTable: FormatRulesetItemGroupTableComponent;

    public tableId: string;

    public subscription: Subscription;
    public ruleSet: FormatRulesetModel;

    public pageIndex: number = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize: number = AppConstants.PAGE_SIZE_DEFAULT;
    public sortProperty: string;
    public sortOrder: SortDirection;
    public search: string;

    public actions: ITableAction[];
    public columns: ITableColumn[] = [];
    public items: FormatRulesetBaseItem[];
    public total: number;
    public disableNextPage = false;
    public TAB_ITEMS = 0;
    public TAB_ASSET_ITEMS = 1;
    public TAB_GROUP_ITEMS = 2;
    public itemsTab = new TabBarItemModel('Library items rules', this.TAB_ITEMS);
    public assetItemsTab = new TabBarItemModel('Asset rules', this.TAB_ASSET_ITEMS);
    public groupItemsTab = new TabBarItemModel('Library item groups', this.TAB_GROUP_ITEMS);
    public tabBarItems: TabBarItemModel[] = [
        this.itemsTab,
        this.assetItemsTab,
        this.groupItemsTab];
    public activeTab: TabBarRoutableItem;
    public ruleSetId: string;
    public ruleProperties: RulePropertyModel[];

    constructor(userSettingsStorageService: UserSettingsStorageService,
                private formatRulesetService: FormatRulesetService,
                private indesignLibraryService: DesignLibraryService,
                private propertyService: PropertyService,
                private route: ActivatedRoute,
                private fullModalService: FullModalService) {
        super(userSettingsStorageService);
    }

    private _selectedTab = this.tabBarItems[this.TAB_ITEMS];

    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
        }
    }

    public ngOnInit(): void {
        this.ruleSetId = this.route.snapshot.params.ruleSetId;
        this.getRuleset();
        this.getRulesetProperties();
    }

    public openRuleItemModal(rulesetItem?: FormatRulesetItemModel): void {
        this.getLibraryItems().subscribe({
            next: (libraryItems) => {
                const data = {
                    ruleSetId: this.ruleSetId,
                    item: rulesetItem,
                    ruleProperties: this.ruleProperties,
                    libraryItems,
                    context: ERulesetContext.ITEMS
                } as IFormatRulesetItemFormComponentData;
                const config = new FullModalConfig(rulesetItem ? 'Edit Rule' : 'Create Rule', 'Setup your rule details', data);
                config.confirmClose = true;
                this.fullModalService.open(FormatRulesetItemFormComponent, config).afterClosed().pipe(
                    filter((result) => !!result)
                ).subscribe(() => {
                    // only reload table when item edited/created is in current tab
                    if (this.selectedTab === this.tabBarItems[this.TAB_ITEMS]) this.rulesetItemTable.reloadTable();
                });
            },
            error: Toaster.handleApiError
        });
    }

    public openRuleAssetItemModal(item?: FormatRulesetAssetItemModel): void {
        const data = {
            ruleSetId: this.ruleSetId,
            item,
            ruleProperties: this.ruleProperties,
            context: ERulesetContext.ASSET_ITEMS
        } as IFormatRulesetAssetItemFormComponentData;
        const config = new FullModalConfig(item ? 'Edit Asset Rule' : 'Create Asset Rule', 'Setup your asset rule details', data);
        config.confirmClose = true;
        this.fullModalService.open(FormatRulesetAssetItemFormComponent, config).afterClosed().pipe(
            filter((result) => !!result)
        ).subscribe(() => {
            // only reload table when item edited/created is in current tab
            if (this.selectedTab === this.tabBarItems[this.TAB_ASSET_ITEMS]) this.rulesetAssetItemTable.reloadTable();
        });
    }

    public openRuleGroupItemModal(item?: FormatRulesetItemGroupModel): void {
        const config = new FullModalConfig(
            item ? 'Edit Item Group' : 'Create Item Group',
            'Setup your item group details',
            {
                ruleSetId: this.ruleSetId,
                itemGroup: item
            } as IFormatRulesetItemGroupFormComponentData);
        config.confirmClose = true;
        this.fullModalService.open(FormatRulesetItemGroupFormComponent, config).afterClosed().pipe(
            filter((result) => !!result)
        ).subscribe(() => {
            // only reload table when item edited/created is in current tab
            if (this.selectedTab === this.tabBarItems[this.TAB_GROUP_ITEMS]) this.rulesetItemGroupTable.reloadTable();
        });
    }

    private getRuleset(): void {
        this.formatRulesetService.getFormatRuleset(this.ruleSetId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => this.ruleSet = result,
                error: Toaster.handleApiError
            });
    }

    /**
     * get a list of possible campaign item properties based on the teamID in the url
     */
    private getRulesetProperties(): void {
        this.propertyService.getProperties(EPropertyContext.INDESIGN_RULESET)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => this.ruleProperties = result.items,
                error: Toaster.handleApiError
            });
    }

    private getLibraryItems(): Observable<DesignLibraryItemModel[]> {
        return this.formatRulesetService.getFormatRuleset(this.ruleSetId).pipe(
            switchMap((result) => {
                return this.indesignLibraryService.getDesignLibraries(AppConstants.PAGE_SIZE_MAX, 0, null, null, null, result.libraries)
                    .pipe(
                        map((pagedResult) => this.groupLibraryItems(pagedResult.items)),
                        takeUntilDestroyed(this.destroyRef)
                    );
            })
        );
    }

    private groupLibraryItems(libraries: DesignLibraryModel[]): DesignLibraryItemModel[] {
        return libraries
            .reduce((acc, val) => {
                for (const item of val.items) {
                    const foundItem = acc.find((i) => i.name === item.name);
                    foundItem ? foundItem.tags = foundItem.tags.concat(item.tags) : acc.push(item);
                }
                return acc;
            }, [])
            .map((item) => {
                item.tags = [...new Set(item.tags)];
                return item;
            });
    }
}
