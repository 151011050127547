import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {ApiService} from './api.service';
import {VideoTemplateModel} from '../../models/api/video-template.model';

@Injectable({
    providedIn: 'root'
})
export class VideoTemplateApiService extends ApiService<VideoTemplateModel> {
    constructor() {
        super([ApiConstants.API_METHOD_VIDEO_TEMPLATES], VideoTemplateModel);
    }
}
