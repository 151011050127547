<div>
    <div class="connection-details-header">
        <h1>Connection details</h1>
        <nuc-button-secondary
            *ngIf="permissions.PATCH_CONNECTION | userIsAllowedTo"
            (click)="editConnection()"
            [disabled]="loading"
            text="Edit">
        </nuc-button-secondary>
    </div>
    <div class="connection-details" *ngIf="connection">
        <div class="left">
            <img class="logo" [src]="logo">
        </div>
        <div class="properties-container">
            <div class="properties">
                <div class="field">
                    <p class="body-strong">Name</p>
                    <p>{{ connection.name }}</p>
                </div>
                <div class="field">
                    <p class="body-strong">API Key</p>
                    <key-viewer [key]="connection.apiKey"
                                [disabled]="loading">
                    </key-viewer>
                </div>
                <div class="field">
                    <p class="body-strong">Global Location Numbers (GLN)</p>
                    <p>{{ connection?.globalLocationNumbers?.length ?
                        (connection.globalLocationNumbers | join) : '-' }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="connection-details-header">
        <h1>Image & product synchronisation details</h1>
        <ng-container *ngIf="permissions.PATCH_CONNECTION | userIsAllowedTo">
            <nuc-button-secondary
                                text="Add item"
                                icon="nucicon_dots_vertical"
                                iconPosition="end"
                                [matMenuTriggerFor]="menu">
            </nuc-button-secondary>
            <mat-menu #menu="matMenu" class="menu-items-container" >
                <button *ngFor="let menuItem of menuItems"
                        mat-menu-item
                        (click)="onMenuItemClicked(menuItem.property)"
                        [disableRipple]="true">
                    Add {{menuItem.title}}
                </button>
            </mat-menu>
        </ng-container>
    </div>
    <div class="synchronisations">
        <div class="items">
            <ng-container *ngIf="connection else loadingIndicator">
                <ng-container *ngIf="connection?.synchronisations?.images.length || connection?.synchronisations?.products.length; else emptyState">
                    <div class="item" *ngFor="let sync of connection?.synchronisations?.images">
                        <div class="properties">
                            <div>
                                <p class="body-strong">Image sync enabled</p>
                                <i [ngClass]="sync.enabled ? 'green' : 'snow'" class="nucicon_check_round_fill"></i>
                            </div>
                            <div>
                                <p class="body-strong">Regular expression</p>
                                <p>{{ sync.regexFilter | nullUndefinedFormatter }}</p>
                            </div>
                            <div>
                                <p class="body-strong">Product datafield</p>
                                <p>{{ sync.productDataField?.name | nullUndefinedFormatter }}</p>
                            </div>
                            <div>
                                <p class="body-strong">Remove leading zeros</p>
                                <i [ngClass]="sync.removeLeadingZeros ? 'green' : 'snow'" class="nucicon_check_round_fill"></i>
                            </div>
                            <nuc-button-bar class="actions">
                                <nuc-button-secondary *ngIf="permissions.PATCH_CONNECTION | userIsAllowedTo"
                                                      icon="nucicon_edit"
                                                      (click)="onEditSynchronisationClicked(sync._id)"
                                                      nucTooltip="Edit item"></nuc-button-secondary>
                                <nuc-button-secondary *ngIf="permissions.PATCH_CONNECTION | userIsAllowedTo"
                                                      icon="nucicon_trash_fill"
                                                      (click)="openDeleteDialog(sync._id)"
                                                      nucTooltip="Delete item"></nuc-button-secondary>
                            </nuc-button-bar>
                        </div>
                    </div>
                    <div class="item" *ngFor="let sync of connection?.synchronisations?.products">
                        <div class="properties">
                            <div>
                                <p class="body-strong">Product sync enabled</p>
                                <i [ngClass]="sync.enabled ? 'green' : 'snow'" class="nucicon_check_round_fill"></i>
                            </div>
                            <div>
                                <p class="body-strong">Mapped fields</p>
                                <p>{{ sync.mapping.length || '-' }}</p>
                            </div>
                            <div>
                                <p class="body-strong">Product datafield</p>
                                <p>{{ sync.productDataField?.name | nullUndefinedFormatter }}</p>
                            </div>
                            <div>
                                <p class="body-strong">Remove leading zeros</p>
                                <i [ngClass]="sync.removeLeadingZeros ? 'green' : 'snow'" class="nucicon_check_round_fill"></i>
                            </div>
                            <nuc-button-bar class="actions">
                                <nuc-button-secondary *ngIf="permissions.PATCH_CONNECTION | userIsAllowedTo"
                                                      icon="nucicon_edit"
                                                      (click)="onEditSynchronisationClicked(sync._id)"
                                                      nucTooltip="Edit item"></nuc-button-secondary>
                                <nuc-button-secondary *ngIf="permissions.PATCH_CONNECTION | userIsAllowedTo"
                                                      icon="nucicon_trash_fill"
                                                      (click)="openDeleteDialog(sync._id)"
                                                      nucTooltip="Delete item"></nuc-button-secondary>
                            </nuc-button-bar>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #loadingIndicator>
                <rl-loading-indicator size="large"></rl-loading-indicator>
            </ng-template>
            <ng-template #emptyState>
                <nuc-empty-state-component imageUrl="/assets/images/general_empty_state.svg"
                                           title="Add your first synchronisation"
                                           [small]="true">
                </nuc-empty-state-component>
            </ng-template>
        </div>
    </div>
</div>
