import {Component, OnInit} from '@angular/core';
import {ComponentsModule} from '../../../../../../components/components.module';
import {MatMenu, MatMenuItem} from '@angular/material/menu';
import {
    FullModalConfig,
    FullModalService,
    NUCButtonBarModule,
    NUCButtonsModule,
    NUCEmptyStateModule,
    NUCTooltipModule
} from '@relayter/rubber-duck';
import {NgForOf, NgIf} from '@angular/common';
import {PipesModule} from '../../../../../../pipes/pipes.module';
import {ConnectionModel, EConnectionType} from '../../../../../../models/api/connection.model';
import {ActivatedRoute} from '@angular/router';
import {ConnectionService} from '../../../../../../api/services/connection.service';
import {RLBaseComponent} from '../../../../../../components/rl-base-component/rl-base.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../../../../classes/toaster.class';
import {ConnectionFormComponent} from '../../../../../../forms/connection-form/connection-form.component';
import {
    IConsumerWebhookEventFormComponentData
} from '../../../../../../forms/connection-form/consumer-webhook/consumer-webhook-event-form/consumer-webhook-event-form.component';
import {
    ProducerWebhookEndpointFormComponent
} from '../../../../../../forms/connection-form/producer-webhook/producer-webhook-endpoint-form/producer-webhook-endpoint-form.component';
import {CollectionDisplayNamePipe} from '../../../../../../pipes/collection-display-name.pipe';

@Component({
    selector: 'standard-connection-detail-webhook-producer',
    standalone: true,
    imports: [
        ComponentsModule,
        MatMenu,
        MatMenuItem,
        NUCButtonBarModule,
        NUCButtonsModule,
        NUCEmptyStateModule,
        NUCTooltipModule,
        NgForOf,
        NgIf,
        PipesModule,
        CollectionDisplayNamePipe
    ],
    templateUrl: './connection-detail-webhook-producer.component.html',
    styleUrl: './connection-detail-webhook-producer.component.scss'
})
export class ConnectionDetailWebhookProducerComponent extends RLBaseComponent implements OnInit {
    private connectionId: string;
    public connection: ConnectionModel;
    public loading: boolean;
    public logo = ConnectionModel.getLogo(EConnectionType.WEBHOOK_PRODUCER);

    constructor(private activeRoute: ActivatedRoute,
                private connectionService: ConnectionService,
                private fullModalService: FullModalService) {
        super();
    }

    public ngOnInit(): void {
        this.activeRoute.params.subscribe((params) => {
            this.connectionId = params.connectionId;
            this.getConnection();
        });
    }

    private getConnection(): void {
        this.connectionService.getConnection(this.connectionId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (connection: ConnectionModel) => {
                    this.connection = connection;
                },
                error: Toaster.handleApiError
            });
    }

    public editConnection(): void {
        // Get fresh copy of the connection
        this.connectionService.getConnection(this.connectionId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: connection => {
                    this.connection = connection;

                    const modalConfig = new FullModalConfig('Edit connection',
                        'You can edit basic connection information.', {connection});
                    modalConfig.confirmClose = true;

                    const modalRef = this.fullModalService.open(ConnectionFormComponent, modalConfig);
                    modalRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
                        return res ? this.getConnection() : null;
                    });
                },
                error: Toaster.handleApiError
            });
    }

    public addEndpoint(): void {
        const modalConfig = new FullModalConfig('Add producer endpoint',
            'Select and configure an endpoint.', {connectionId: this.connectionId} as IConsumerWebhookEventFormComponentData);
        const modalRef = this.fullModalService.open(ProducerWebhookEndpointFormComponent, modalConfig);
        modalRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
            res ? this.getConnection() : null;
        });
    }

}
