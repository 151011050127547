import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList, Signal, signal,
    SimpleChanges, viewChild,
    ViewChild,
    ViewChildren, WritableSignal
} from '@angular/core';
import {CustomWorkflowPreviewDataService} from '../custom-workflow-preview-data.service';
import {
    CustomWorkflowActionModel
} from '../../../../../../../models/api/custom-workflow-action.model';
import {combineLatest, ReplaySubject, Subject} from 'rxjs';
import {CustomWorkflowComponentModel} from '../../../../../../../models/api/custom-workflow-component.model';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {CustomWorkflowService} from '../../custom-workflow.service';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {DropdownItem} from '../../../../../../../models/ui/dropdown-item.model';
import {FilesRevisionModel, PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {EItemSelectionType} from '../../custom-workflow-item-selection/custom-workflow-item-selection.component';
import {FormControl, FormGroup} from '@angular/forms';
import {VariantModel} from '../../../../../../../models/api/variant.model';
import {SizeModel} from '../../../../../../../models/api/size.model';
import {TemplateModel} from '../../../../../../../models/api/template.model';
import {AssetFileTypePipe} from '../../../../../../../pipes/asset-file-type.pipe';
import {ImageStickyNotesViewComponent} from './image-sticky-notes-view/image-sticky-notes-view.component';
import {VideoStickyNotesViewComponent} from './video-sticky-notes-view/video-sticky-notes-view.component';

export enum EPreviewOverlay {
    NONE = 'NONE',
    NOTES = 'NOTES',
    NOTES_BACKGROUND = 'NOTES_BACKGROUND'
}

@Component({
    selector: 'rl-preview-sticky-notes-view',
    templateUrl: 'preview-sticky-notes-view.component.html',
    styleUrls: ['preview-sticky-notes-view.component.scss'],
    animations: [
        trigger('newStickyNote', [
            transition(':enter', [
                style({transform: 'scale(0,0)'}),
                animate('0.25s cubic-bezier(.41,.05,.48,1.46)', style({transform: 'scale(1,1)'})),
            ])
        ]),
    ]
})
export class PreviewStickyNotesViewComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('imagesContainer') public imagesContainer: ElementRef;
    @ViewChild('container', {static: true}) public container: ElementRef;
    public imageStickyNoteView = viewChild<ImageStickyNotesViewComponent>(ImageStickyNotesViewComponent);
    public videoStickyNoteView = viewChild<VideoStickyNotesViewComponent>(VideoStickyNotesViewComponent);
    @ViewChildren('image') public images: QueryList<ElementRef>;
    public drag: Signal<CdkDrag> = viewChild<CdkDrag>(CdkDrag);

    @Input() public previewLoading = false;
    @Input() public itemSelectionType: EItemSelectionType;
    @Input() public horizontal: boolean = false;

    public loadingImage = false;
    public actions: CustomWorkflowActionModel[] = [];
    // Init with empty array to prevent forEach of undefined when variant is switched before publication items are loaded.
    public publicationItems: PublicationItemModel[] = [];

    public activeVariant: VariantModel = null;

    public component: CustomWorkflowComponentModel;
    public publicationId: string;
    private publicationType: string;

    public filesVersionControl = new FormControl();
    public formGroup = new FormGroup({filesVersion: this.filesVersionControl});
    public selectedFilesVersion: FilesRevisionModel;
    public filesVersions: FilesRevisionModel[];

    public publicationItems$ = new ReplaySubject<PublicationItemModel[]>(1);
    public publicationItemSignal = signal<PublicationItemModel[]>(null);

    private onDestroySubject = new Subject<void>();

    public previewOverlays: DropdownItem<string>[] = [
        new DropdownItem('Show notes', EPreviewOverlay.NOTES),
        new DropdownItem('Highlight notes', EPreviewOverlay.NOTES_BACKGROUND),
        new DropdownItem('Hide notes', EPreviewOverlay.NONE)];
    public overlay: string = EPreviewOverlay.NOTES;
    public previewOverlayEnum = EPreviewOverlay;

    public zoomLevel = 1;
    public dragging: boolean = false;
    public disableZoomTransition: boolean = false;

    public minZoomLevel: WritableSignal<number> = signal<number>(0);
    public placeHolderImage = '';

    constructor(private previewDataService: CustomWorkflowPreviewDataService,
                private customWorkflowService: CustomWorkflowService) {
    }

    public ngOnInit(): void {
        combineLatest([
            this.previewDataService.actions$,
            this.customWorkflowService.publication$,
            this.customWorkflowService.activeComponent$
        ]).pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe(([actions, publication, component]) => {
            this.actions = actions;
            this.publicationId = publication._id;
            this.component = component;
            this.publicationType = publication.channel.name;
        });

        this.filesVersionControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.onDestroySubject)
            )
            .subscribe((value) => {
                this.selectedFilesVersion = value;
                this.previewDataService.setSelectedFilesRevision(value);

                this.publicationItems$.next(this.publicationItems.map((pubItem) => {
                    const itemFiles = this.selectedFilesVersion.getValue();

                    pubItem.previewImage = itemFiles?.preview?.url;
                    pubItem.previewType = AssetFileTypePipe.format(itemFiles?.preview?.extension);
                    pubItem.sourceFile = itemFiles?.source?.url;

                    return pubItem;
                }));
            });

        this.previewDataService.publicationItems$
            .pipe(map((publicationItems) => {
                publicationItems.forEach((item: PublicationItemModel) => {
                    item.previewImage = item.getVariantFiles(this.activeVariant?._id)?.preview?.url;
                    item.previewType = AssetFileTypePipe.format(item.getVariantFiles(this.activeVariant?._id)?.preview?.extension);
                });
                this.publicationItems$.next(publicationItems);
                return publicationItems;
            }),takeUntil(this.onDestroySubject))
            .subscribe((publicationItems) => {
                this.loadingImage = true;
                this.publicationItems = publicationItems;
                this.publicationItemSignal.set(publicationItems)
                setTimeout(() => {},1);
                this.setupFilesRevisionsForm();
                this.createImagePlaceHolder();
            });

        this.customWorkflowService.activeVariant$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((variant: VariantModel) => {
                this.activeVariant = variant;
                this.setupFilesRevisionsForm();
                this.publicationItems.forEach((item) => {
                    item.previewImage = item.getVariantFiles(this.activeVariant?._id)?.preview?.url;
                    item.previewType = AssetFileTypePipe.format(item.getVariantFiles(this.activeVariant?._id)?.preview?.extension);
                });
                this.createImagePlaceHolder();
            });
    }

    private setupFilesRevisionsForm(): void {
        if (this.itemSelectionType === EItemSelectionType.ITEM_SELECTION && this.publicationItems?.length === 1) {
            const publicationItem = this.publicationItems[0];
            const currentVersion = new FilesRevisionModel();
            currentVersion.getTitle = () => 'Current version';
            currentVersion.date = publicationItem.updatedAt;
            currentVersion.files = publicationItem.getVariantFiles(this.activeVariant?._id);
            currentVersion.variant = this.activeVariant?._id;

            // concat the current version
            const itemFilesVersions = publicationItem.getVariantFilesRevisions(this.activeVariant?._id);
            this.filesVersions = itemFilesVersions.length > 0
                ? [currentVersion].concat([...itemFilesVersions].reverse())
                : [currentVersion];
            this.filesVersionControl.patchValue(currentVersion); // always select the current one first
        } else {
            this.filesVersions = null;
            this.selectedFilesVersion = null;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.itemSelectionType && !changes.itemSelectionType.firstChange) {
            this.setupFilesRevisionsForm();
        }
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * Zoom in with increments of 25% till the max zoom of 3x is reached.
     */
    public zoomIn(): void {
        this.zoomLevel = Math.min(this.zoomLevel + .25, 3);
    }

    /**
     * Zoom out with increments of 25% till zoom level of 95% is reached.
     */
    public zoomOut(): void {
        this.zoomLevel = Math.max(this.zoomLevel - .25, this.minZoomLevel());
    }

    public fitToContainer(): void {
        this.imageStickyNoteView()?.fitToContainer();
        this.videoStickyNoteView()?.fitToContainer();
    }

    public getZoomValue(): string {
        return Math.round(this.zoomLevel * 100) + '%';
    }

    /**
     * Set the overlay on selection of dropdown item
     * @param {string} overlay
     */
    public setOverlay(overlay: string) {
        this.overlay = overlay;
    }

    /**
     * Update the zoom level realtime if the slider is moved
     * @param {MatSliderChange} sliderChange
     */
    public valueChanged(sliderChange: number): void {
        if (!this.disableZoomTransition) {
            this.disableZoomTransition = true;
        }
        this.zoomLevel = sliderChange / 100;
    }

    private createImagePlaceHolder(): void {
        this.placeHolderImage = '';
        if (this.publicationItems?.length > 0 && !this.publicationItems.every(item =>
            item.files.find(files => files.variant === this.activeVariant?._id))) {
            const publicationItem = this.publicationItems[0];
            this.placeHolderImage = this.createCustomImage(publicationItem.template?.pageSize || TemplateModel.defaultPageSize(this.publicationType));
        }
    }

    public createCustomImage(itemSize: SizeModel): string {
        // create an off-screen canvas (default size from A4)
        const width = itemSize.width || 1414;
        const height = itemSize.height || 2000;
        const factor = Math.min(2000 / width, 2000 / height);

        const canvas = document.createElement('canvas');

        // set its dimension to target size (boxed into 2000 x 2000)
        canvas.width = width * factor;
        canvas.height = height * factor;

        // encode image to data-uri with base64 version of compressed image
        const encodedData = canvas.toDataURL();
        canvas.remove();

        return encodedData;
    }

    public setMinZoomLevel(minLevel: number) {
        this.minZoomLevel.set(minLevel)
    }

}
