import {autoserialize, autoserializeAs, deserializeAs, serialize, serializeAs} from 'cerialize';
import {SizeModel} from './size.model';
import {
    TemplatePresetModel,
    TemplateVariantPresetBodyModel
} from '../../pages/relayter/templates/template-detail/template-preset/template-preset.model';
import {
    ETemplateType,
    TemplateSizeModel
} from '../../pages/relayter/templates/template-detail/template-size/template-size.model';
import {PointModel} from './point.model';
import {TemplateMarginsModel} from './template-margins.model';
import {TemplateTypeModel} from './template-type.model';
import {IdSerializer} from '../../classes/id-serializer';
import {TemplateAreaModel} from './template-area.model';
import {
    TemplateContentModel
} from '../../pages/relayter/templates/template-detail/template-content/template-content.model';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {MasterPageModel} from './master-page.model';
import {ITableItem} from '@relayter/rubber-duck';
import {
    TemplateVariantModel
} from '../../pages/relayter/templates/template-detail/template-variant/template-variant.model';
import {TemplateVariantPresetModel} from './template-variant-preset.model';
import {DesignLibraryModel} from '../../modules/format-rulesets/models/api/design-library.model';
import {FormatRulesetModel} from '../../modules/format-rulesets/models/api/format-ruleset.model';

export enum EEngineType {
    INDESIGN = 'INDESIGN',
    SVG = 'SVG'
}

export class TemplateModel implements ITableItem, IDropdownItem {
    @autoserialize public name: string;
    @autoserializeAs(SizeModel) public pageSize: SizeModel = new SizeModel();
    @autoserializeAs(TemplateAreaModel) public areas: TemplateAreaModel[] = [];
    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(TemplateMarginsModel) public margins: TemplateMarginsModel;
    @autoserialize public _id: string;
    @autoserialize public pages: number;
    @autoserialize public tags: string[];
    @autoserialize public masterPage: string;
    @autoserialize public indesignLibrary: string;
    @autoserializeAs('indesignRuleSet') public formatRuleset: string;
    @autoserialize public channel: string; // id of the channel
    @deserializeAs(TemplateTypeModel)
    @serializeAs(new IdSerializer())
    public templateType: TemplateTypeModel;
    @autoserialize public itemsGenerated: number;
    @autoserialize public engineType: EEngineType;

    getTitle(): string {
        return this.name;
    }

    getValue(): string {
        return this._id;
    }

    static defaultPageSize(publicationType): SizeModel {
        const templateSize = TemplateSizeModel.defaultSize(publicationType);
        return new SizeModel(templateSize.width, templateSize.height);
    }
}

export class TemplateDetailModel {
    @autoserialize public name: string;
    @autoserializeAs(SizeModel) public pageSize: SizeModel = new SizeModel();
    @autoserializeAs(TemplateAreaModel) public areas: TemplateAreaModel[] = [];
    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(TemplateMarginsModel) public margins: TemplateMarginsModel;
    @autoserialize public _id: string;
    @autoserialize public pages: number;
    @autoserialize public tags: string[];
    @autoserialize public channel: string; // id of the channel
    @deserializeAs(TemplateTypeModel)
    @serializeAs(new IdSerializer())
    public templateType: TemplateTypeModel;
    @autoserialize public itemsGenerated: number;
    @autoserialize public engineType: EEngineType;

    // difference between TemplateDetailModel and TemplateModel
    @autoserializeAs(MasterPageModel) public masterPage: MasterPageModel;
    @autoserializeAs(DesignLibraryModel) public indesignLibrary: DesignLibraryModel;
    @autoserializeAs(FormatRulesetModel, 'indesignRuleSet') public formatRuleset: FormatRulesetModel;
    @autoserializeAs(TemplateVariantPresetModel) variantPresets: TemplateVariantPresetModel[];
}

export class TemplateBodyModel {
    @serialize public name: string;
    @serialize public pageSize: SizeModel = new SizeModel();
    @serialize public areas: TemplateAreaModel[] = [];
    @serialize public margins: TemplateMarginsModel;
    @serialize public pages: number;
    @serialize public tags: string[];
    @serialize public masterPage: string;
    @serialize public indesignLibrary: string;
    @serializeAs('indesignRuleSet') public formatRuleset: string;
    @serialize public channel: string;
    @serialize public templateType: string;
    @autoserializeAs(TemplateVariantPresetBodyModel) variantPresets: TemplateVariantPresetBodyModel[];

    private static transformContentsToAreas(contents: TemplateContentModel[]): TemplateAreaModel[] {
        return contents.map((content) => {
            return new TemplateAreaModel(
                new PointModel(content.x, content.y),
                new SizeModel(content.width, content.height),
                content.columns,
                content.rows,
                content.columnGutter,
                content.rowGutter,
                content._id
            );
        });
    }

    public static templateFromTemplateEditorModels(publicationType: EPublicationType,
                                                   presetModel: TemplatePresetModel,
                                                   sizeModel: TemplateSizeModel,
                                                   templateVariant: TemplateVariantModel,
                                                   contents: TemplateContentModel[]): TemplateBodyModel {
        const template = new TemplateBodyModel();
        template.name = presetModel.name;
        if (presetModel.masterPage) {
            template.masterPage = presetModel.masterPage._id;
        }

        template.tags = presetModel.tags ? presetModel.tags.map((tag) => tag.getValue()) : [];
        template.indesignLibrary = presetModel.library?._id;
        template.formatRuleset = presetModel.ruleSet?._id;
        template.pageSize = new SizeModel(sizeModel.width, sizeModel.height);
        template.margins = new TemplateMarginsModel(
            sizeModel.margins.marginTop, sizeModel.margins.marginBottom, sizeModel.margins.marginStart, sizeModel.margins.marginEnd
        );
        template.pages = sizeModel.templateType === ETemplateType.Spread ? 2 : 1;
        template.areas = this.transformContentsToAreas(contents);

        // some properties that depends on publicationType
        if (publicationType === EPublicationType.POS) {
            template.templateType = presetModel.templateType._id;
        }

        if (templateVariant) {
            template.variantPresets = templateVariant.variantPresets.map(preset => new TemplateVariantPresetBodyModel(preset));
        }

        return template;
    }

}
