@if (form) {
    <form [formGroup]="form">
        <div class="general-input">
        <nuc-form-field label="Model">
            <nuc-dropdown formControlName="model"
                          [items]="models"
                          placeholder="Select an model">
            </nuc-dropdown>
        </nuc-form-field>
        <nuc-form-field label="Action">
            <nuc-dropdown formControlName="action"
                          [items]="actions"
                          placeholder="Select how to process the incoming events">
            </nuc-dropdown>
        </nuc-form-field>
        <div class="section-header identifier">
            Identifiers
            <p>You can use this field to identify and take actions on items.</p>
        </div>
            @if (form.controls.masterBriefing) {
                <nuc-form-field>
                    <nuc-checkbox text="Import to Master briefing" formControlName="masterBriefing"></nuc-checkbox>
                </nuc-form-field>
            }
            @if (form.controls.campaignNamePath) {
                <nuc-form-field label="Campaign name">
                    <nuc-input formControlName="campaignNamePath" placeholder="Path for campaign name"/>
                </nuc-form-field>
            }
            @if (form.controls.campaignDatePath) {
                <nuc-form-field label="Campaign date">
                    <nuc-input formControlName="campaignDatePath" placeholder="Path for campaign date"/>
                </nuc-form-field>
            }
            <nuc-form-field label="Path">
                <nuc-input formControlName="path" placeholder="Path for item identifier"/>
            </nuc-form-field>
            <nuc-form-field label="Field">
                <nuc-dropdown formControlName="field"
                              placeholder="Select an identifier"
                              [items]="identifierFields()">
                </nuc-dropdown>
            </nuc-form-field>

        </div>
        <div class="mappings-container">
            <div class="header-row">
                <div class="text">
                    <h1>Mappings</h1>
                    <p>Add data fields and map it to their external path from the producer</p>
                </div>
                <nuc-button-secondary text="Add mapping" icon="nucicon_add" (click)="addMapping()"></nuc-button-secondary>
            </div>
            @for (mapping of mappings.controls; track mapping; let index = $index; let isFirst = $first){
                <div class="mapping-item" [formGroup]="mapping">
                    <nuc-form-field [label]="isFirst ? 'Path' : null">
                        <nuc-input formControlName="path" placeholder="Property to map"/>
                    </nuc-form-field>
                    <nuc-form-field class="property" [label]="isFirst ? 'Property' : null">
                        <property-control class="property"
                            formControlName="property"
                            [ruleProperties]="properties()"
                            [ignoreIndex]="true"
                            [variants]="variants"
                            [variantRequired]="true"/>
                    </nuc-form-field>
                    <nuc-button-secondary [class.first]="isFirst" icon="nucicon_trash_line" (click)="deleteMapping(index)"/>
                </div>
            }
        </div>
    </form>
}
