import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RLValidatorConstants} from '../../../classes/validators/rl-validators.constant';
import {EUploadStatus} from '../../../components/upload-file-component/upload.model';
import {IUploadUpdate} from '../../../components/upload-file-component/upload-file.component';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {
    DesignLibraryModel,
    DesignLibraryPatchModel
} from '../../../modules/format-rulesets/models/api/design-library.model';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {
    ELibraryJobTypes,
    ICreateLibraryJobData,
    ILibraryJobData,
    DesignLibraryService,
    IUpdateLibraryJobData
} from '../../../api/services/design-library.service';
import {JobModel} from '../../../models/api/job.model';
import {ARApiError, ARLogger} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {FileTypeUtil} from '../../../classes/file-type.util';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {EEngineType} from '../../../models/api/template.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import DesignLibraryFormGroup from '../design-library-form-group.interface';

export interface ILibraryFormData {
    library?: DesignLibraryModel;
}

export interface ILibraryFormResult {
    jobId?: string;
}

@Component({
    selector: 'rl-indesign-library-form-component',
    templateUrl: 'indesign-library-form.component.html',
    styleUrls: ['indesign-library-form.component.scss']
})
export class IndesignLibraryFormComponent implements OnInit {
    public form: FormGroup<DesignLibraryFormGroup>;
    public validationMessages = {
        name: RLValidatorConstants.MESSAGE_SETS.REQUIRED
    };

    private destroyRef = inject(DestroyRef);
    public modalData: ILibraryFormData = inject(NUC_FULL_MODAL_DATA);
    private fullModalService: FullModalService = inject(FullModalService);
    private designLibraryService: DesignLibraryService = inject(DesignLibraryService);

    private saveButton: ButtonConfig;

    public fileTypeCategories = [FileTypeUtil.CATEGORIES.INDESIGN_LIBRARY];
    private uploadData: IUploadUpdate;

    public uploadUpdateSubject = new BehaviorSubject<IUploadUpdate>(null);

    public engineTypes = [
        new DropdownItem(EEngineType.INDESIGN, EEngineType.INDESIGN)
    ];

    public ngOnInit(): void {
        this.setupForm();
        this.setupModalActions();
        this.trackStatus();
    }

    private setupForm(): void {
        this.form = new FormGroup({
            name: new FormControl(this.modalData.library?.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            engineType: new FormControl({value: this.engineTypes[0], disabled: true}, Validators.required),
        });
    }

    private setupModalActions(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null);
        const saveAction = new FullModalActionModel(this.saveButton);
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.modalData.library ? this.updateLibrary() : this.createLibrary();
        });

        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private trackStatus(): void {
        combineLatest([
            this.form.statusChanges.pipe(startWith(this.form.status), distinctUntilChanged(), map((status) => status === 'VALID')),
            this.uploadUpdateSubject.pipe(map((uploadUpdate) => {
                this.uploadData = uploadUpdate;
                return uploadUpdate?.status === EUploadStatus.Done || (this.modalData.library && !uploadUpdate);
            }))])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([formValid, uploadValid]) => this.saveButton.disabled = !formValid || !uploadValid);
    }

    private updateLibrary(): void {
        if (this.uploadData?.s3Key) {
            const jobData =
                {libraryId: this.modalData.library._id, name: this.form.value.name.trim(), s3Key: this.uploadData.s3Key} as IUpdateLibraryJobData;
            this.postLibraryJob(ELibraryJobTypes.UPDATE_INDESIGN_LIBRARY_JOB, jobData);
        } else {
            const body = new DesignLibraryPatchModel();
            body.name = this.form.value.name.trim();
            this.designLibraryService.patchLibrary(this.modalData.library._id, body).subscribe({
                next: () => {
                    this.fullModalService.close({});
                    Toaster.success('InDesign library updated successfully');
                },
                error: this.handleError
            });
        }
    }

    private createLibrary(): void {
        const jobData = {name: this.form.value.name.trim(), s3Key: this.uploadData.s3Key} as ICreateLibraryJobData;
        this.postLibraryJob(ELibraryJobTypes.CREATE_INDESIGN_LIBRARY, jobData);
    }

    private postLibraryJob(jobType: ELibraryJobTypes, jobData: ILibraryJobData): void {
        this.designLibraryService.postJob(jobType, jobData).subscribe({
            next: (job: JobModel) => {
                ARLogger.debug('InDesign Library Job scheduled: ' + job._id);
                this.fullModalService.close({jobId: job._id} as ILibraryFormResult); // close with job id for refreshing
            },
            error: this.handleError
        });
    }

    private handleError(error: ARApiError): void {
        Toaster.handleApiError(error);
        this.saveButton.loading = false;
    }
}
