import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {ARPagedResponseDataModel} from '@relayter/core';
import {TemplateService} from '../../../../api/services/templates.service';
import {EEngineType, TemplateDetailModel, TemplateModel} from '../../../../models/api/template.model';
import {TemplateUsageModel} from '../../../../models/api/template-usage.model';
import {AppConstants} from '../../../../app.constants';
import {TableActionTypeModel} from '../../../../models/ui/table-action-type.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {EPublicationType} from '../template-detail/publication-type.enum';
import {
    BUTTON_TYPE,
    DialogCustomContentConfig,
    EColumnSize,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    ISortOptionEvent,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogCustomContentService,
    NucDialogService
} from '@relayter/rubber-duck';
import {of, Subject, Subscription} from 'rxjs';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {publicationTypeDisplayNames} from '../../../../models/api/channel.model';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {concatMap, filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {TemplateInUseDialogComponent} from './template-in-use/template-in-use-dialog.component';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {MonitoredJobsService} from '../../../../api/services/monitored-jobs.service';
import {ETemplateType} from '../template-detail/template-size/template-size.model';

@Component({
    selector: 'rl-template-overview',
    templateUrl: 'template-overview.component.html',
    styleUrls: ['template-overview.component.scss'],
    providers: [PaginatorService]
})

export class TemplateOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public tableId: string;
    public viewId: string;

    public templates: TemplateModel[] = [];

    @ViewChild('createTemplateModal', {static: true})
    protected createTemplateModal: TemplateRef<any>;

    public template: TemplateModel = new TemplateModel();

    public columns: ITableColumn[] = [{
        title: 'Name',
        key: 'name',
        sortProperty: 'name',
        size: EColumnSize.LARGE
    }, {
        title: 'Page size',
        key: 'pageSize',
        sortProperty: 'pageSize.width',
        format: (value) => `${value.width} x ${value.height}`
    }, {
        title: 'Tags',
        key: 'tags',
        sortProperty: 'tags'
    }, {
        title: 'Date created',
        key: 'createdAt',
        sortProperty: 'createdAt',
        size: EColumnSize.SMALL,
        format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
    }];

    public actionTypes: TableActionTypeModel[] = [];

    public pageIndex: number;
    public pageSize: number;
    public totalItemCount: number;
    public disableNextPage = true;

    public selectedTemplate: TemplateDetailModel;
    public publicationType: EPublicationType;
    public publicationTypeDisplayName: string;

    private onDestroySubject = new Subject<void>();
    public subscription: Subscription;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private fullModalService: FullModalService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private templateService: TemplateService,
                private dialogService: NucDialogService,
                private dialogCustomContentService: NucDialogCustomContentService,
                private paginatorService: PaginatorService,
                private monitoredJobsService: MonitoredJobsService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    /**
     * On authenticated init
     * Check if the user is allowed to view this page component
     */
    public ngOnInit(): void {
        this.initFromRoute();

        if (this.publicationType === EPublicationType.POS) {
            const templateTypeColumn: ITableColumn = {
                title: 'Template type',
                key: 'templateType.name',
                sortProperty: 'templateType.name',
                size: EColumnSize.LARGE
            };

            this.columns.splice(1, 0, templateTypeColumn);
        }

        if (this.publicationType === EPublicationType.PRINT_MAGAZINE) {
            const pagesColumn: ITableColumn = {
                title: 'Type',
                key: 'pages',
                sortProperty: 'pages',
                size: EColumnSize.SMALL,
                format: (v) => v === 1 ? ETemplateType.Single : ETemplateType.Spread
            };

            this.columns.splice(1, 0, pagesColumn);
        }

        this.setTableActions();

        this.paginatorService.getPagination(this.viewId).subscribe((result: { pageIndex: number; pageSize: number }) => {
            this.pageIndex = result.pageIndex;
            this.pageSize = result.pageSize;
            this.updateUrl();
            this.getTemplates();
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * Subscribes to route params
     * When the table options are changed, the url is updated
     * This subscription forces a refresh of the data
     */
    private initFromRoute(): void {
        this.publicationType = this.route.snapshot.data['publicationType'];
        this.publicationTypeDisplayName = publicationTypeDisplayNames.get(this.publicationType);

        this.tableId = `template-overview-${this.publicationType}`;
        this.viewId = this.tableId;

        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_TEMPLATES)) {
            return;
        }

        const params = this.route.snapshot.params;
        this.searchValue = params['search'] ? params['search'] : '';
        this.sortOrder = params['sortOrder'] ? params['sortOrder'] : '';
        this.sortProperty = params['sortProperty'] ? params['sortProperty'] : '';
        this.pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;

        this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(this.permissions.PUT_TEMPLATE)) {
            this.actionTypes = [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
        if (this.userIsAllowedToPipe.transform(this.permissions.DELETE_TEMPLATE)) {
            this.actionTypes.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }
    }

    /**
     * Reload the data
     */
    public reload(): void {
        // If the index did not change, force reload
        if (this.pageIndex !== 1) {
            this.templates = [];
            this.searchValue = '';
            this.pageIndex = 1;
            this.totalItemCount = 0;
        }
        this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
    }

    public openTemplateEditor(template?: TemplateModel): void {
        const modalTitle = `${this.publicationTypeDisplayName} template editor`;
        const modalDescription = `Define your ${this.publicationTypeDisplayName} template and setup a master page.`;

        const config = new FullModalConfig(modalTitle, modalDescription);
        config.confirmClose = true;

        const observable = template ? this.templateService.getTemplateDetails(template._id) : of(null);

        observable.pipe(
            tap((item) => this.selectedTemplate = item),
            concatMap(() => this.fullModalService.open(this.createTemplateModal, config).afterClosed()),
            takeUntil(this.onDestroySubject)
        ).subscribe((refresh: boolean) => {
            this.selectedTemplate = null;
            if (refresh) this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
        });
    }

    /**
     * Get a paged list of the templates, and set the this.templates with the result
     */
    private getTemplates(): void {
        this.subscription?.unsubscribe();

        this.subscription = this.templateService.getTemplates(this.publicationType,
            EEngineType.INDESIGN,
            this.pageSize,
            (this.pageIndex - 1) * this.pageSize,
            null,
            undefined,
            this.searchValue,
            null,
            this.sortProperty,
            this.sortOrder
        ).subscribe({
            next: (res: ARPagedResponseDataModel<TemplateModel>) => {
                this.totalItemCount = res.total;
                this.templates = res.items;
                this.disableNextPage = this.pageIndex * this.pageSize > this.totalItemCount;
            },
            error: Toaster.handleApiError
        });
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortProperty = sortEvent.column.sortProperty;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = null;
            this.sortOrder = '';
        }

        this.pageIndex = 1;
        this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
    }

    public onSearchBarValueUpdated(searchValue: string): void {
        this.searchValue = searchValue;

        this.pageIndex = 1;
        this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
    }

    /**
     * Handles clicks on table row actions ( edit | delete )
     */
    public handleTableRowAction(event: IActionClickEvent): void {
        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.editTemplate(event.item as TemplateModel);
        }
        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.checkUsageAndDeleteTemplateDialog(event.item as TemplateModel);
        }
    }

    /**
     * Check if template is in use, if not open the template component
     * @param {TemplateModel} template
     */
    public editTemplate(template: TemplateModel): void {
        this.templateService.getTemplateUsage(template._id).subscribe({
            next: (templateUsage: TemplateUsageModel) => {
                if (templateUsage.campaigns.length > 0) {
                    // when editing, we don't have to care about the usage in workflow automations
                    this.openEditDialog(template);
                } else {
                    this.openTemplateEditor(template);
                }
            },
            error: Toaster.handleApiError
        });
    }

    private openEditDialog(template: TemplateModel): void {
        const editDialogConfig = new NucDialogConfigModel('Are you sure?',
            'This template is currently used in one or more publication items in Relayter, editing it may break their layout.');
        const editDialog = this.dialogService.openDialog(editDialogConfig);
        editDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => editDialog.close());
        editDialogConfig.addAction('Confirm', BUTTON_TYPE.PRIMARY).subscribe(() => {
            editDialog.close();
            this.openTemplateEditor(template);
        });
    }

    private checkUsageAndDeleteTemplateDialog(template: TemplateModel): void {
        this.templateService.getTemplateUsage(template._id)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (usage: TemplateUsageModel) => {
                    if (usage.campaigns.length > 0 || usage.workflowAutomations.length > 0) {
                        this.openTemplateInUseModal(usage, template);
                    } else {
                        this.openDeleteDialog(template);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private openDeleteDialog(template: TemplateModel): void {
        const deleteDialogConfig = new NucDialogConfigModel(`Delete ${this.publicationTypeDisplayName} template`,
            `Please confirm that you wish to delete ${template.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteTemplate(template);
        });
    }

    private openTemplateInUseModal(templateUsage: TemplateUsageModel, template: TemplateModel): void {
        const dialogConfig = new DialogCustomContentConfig(`Delete ${this.publicationTypeDisplayName} template`,
            'This template is being used by the following items:',
            {templateUsage, template, publicationType: this.publicationType});
        this.dialogCustomContentService.open(TemplateInUseDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter((result: JobModel) => !!result),
                switchMap((result: JobModel) => this.monitoredJobsService.getJobMonitor(result._id)),
                filter((result: JobModel) => result.status === EJobStatus.DONE),
                takeUntil(this.onDestroySubject)
            ).subscribe({
            next: () => {
                this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
            },
            error: Toaster.handleApiError
        });
    }

    /**
     * Responder. Invokes the deletion of the template
     * @param {TemplateModel} template
     * @returns {void}
     */
    public deleteTemplate(template: TemplateModel): void {
        this.templateService.deleteTemplate(template._id)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (res: boolean) => {
                    if (res) {
                        Toaster.success('Template successfully deleted');
                        if (this.templates.length > 1) {
                            this.paginatorService.setPageIndex(this.viewId, this.pageIndex);
                        } else {
                            this.reload();
                        }
                    } else {
                        Toaster.error('There was an error while deleting the template');
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private updateUrl(): void {
        this.router.navigate([this.createMatrixUrl()], {relativeTo: this.route});
    }

    /**
     * Create a MatrixUrlModel so the url always has the correct amount of parameters
     * @return {MatrixUrlParams}
     */
    public createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }
}
