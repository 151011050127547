@if (formGroup) {
    <form [formGroup]="formGroup">
        <div>
            <h2>Preset</h2>
            <hr>

            <nuc-form-field label="Name">
                <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Format ruleset">
                <nuc-dropdown
                        formControlName="videoRuleSet"
                        placeholder="Select a video ruleset"
                        [nullOption]="false"
                        [items]="videoRulesets"
                        [required]="true">
                </nuc-dropdown>
            </nuc-form-field>

            <nuc-form-field label="Video engine type">
                <nuc-dropdown
                        formControlName="engineType"
                        placeholder="Select a video engine type"
                        [nullOption]="false"
                        [items]="videoEngineTypes"
                        [required]="true">
                </nuc-dropdown>
            </nuc-form-field>

            <nuc-form-field label="After Effects project file">
                <nuc-dropdown
                        formControlName="afterEffectsProjectFile"
                        placeholder="Select an After Effects project file"
                        [searchable]="true"
                        [nullOption]="false"
                        [items]="afterEffectsProjectFiles"
                        [total]="afterEffectsProjectFilesTotalItems"
                        (requestData)="requestAfterEffectsProjectFiles($event)"
                        [required]="true">
                </nuc-dropdown>
            </nuc-form-field>
        </div>
        <div>
            <h2>Variants</h2>
            <p>
                Add one or more variants with their appropriate After Effects project file and video ruleset.
            </p>
            <hr>

            <nuc-form-field>
                <nuc-label icon="nucicon_variant" iconColorClass="variant-highlight">Variant(s)</nuc-label>
                <nuc-dropdown-multiselect class="variant"
                                          placeholder="Select variants"
                                          [data]="selectedVariants"
                                          (dataChange)="onVariantsChanged($event)"
                                          [items]="variants">
                </nuc-dropdown-multiselect>
            </nuc-form-field>

            <ng-container formArrayName="variantsPresets">
                @for (control of variantPresetFormArray.controls; track control) {
                    <div [formGroup]="control">
                        <nuc-label icon="nucicon_variant" iconColorClass="variant-highlight">{{ control.value.variant.name }}</nuc-label>
                        <nuc-form-field label="After Effects project file">
                            <nuc-dropdown
                                formControlName="afterEffectsProjectFile"
                                placeholder="Select an After Effects project file"
                                [searchable]="true"
                                [nullOption]="false"
                                [items]="afterEffectsProjectFiles"
                                [total]="afterEffectsProjectFilesTotalItems"
                                (requestData)="requestAfterEffectsProjectFiles($event)"
                                [required]="true">
                            </nuc-dropdown>
                        </nuc-form-field>
                        <nuc-form-field label="Format ruleset">
                            <nuc-dropdown
                                formControlName="videoRuleSet"
                                placeholder="Select a video ruleset"
                                [nullOption]="false"
                                [items]="videoRulesets"
                                [required]="true">
                            </nuc-dropdown>
                        </nuc-form-field>
                        @if (!$last) {
                            <hr>
                        }
                    </div>
                }
            </ng-container>
        </div>
    </form>
}
