import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {
    AfterEffectsProjectFileCompositionLayerModel,
    AfterEffectsProjectFileCompositionModel,
    AfterEffectsProjectFileModel
} from '../../models/api/after-effects-project-file.model';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {MonitoredJobsService} from './monitored-jobs.service';
import {TableSortOptions} from '../table-sort-options';
import {NewCursor} from '../new-api-cursor';
import {ARPagedResponseDataModel} from '@relayter/core';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {ApiDetailsRequest} from '../requests/api-details.request';

export enum EAfterEffectsProjectFilesJobType {
    ADD_AFTER_EFFECTS_PROJECT_FILE_JOB = 'ADD_AFTER_EFFECTS_PROJECT_FILE_JOB',
    UPDATE_AFTER_EFFECTS_PROJECT_FILE_JOB = 'UPDATE_AFTER_EFFECTS_PROJECT_FILE_JOB'
}

export interface IAddAfterEffectsProjectFileJobData {
    name: string;
    s3Key: string;
}

export interface IUpdateAfterEffectsProjectFileJobData {
    itemId: string;
    name: string;
    s3Key: string;
}

export type IAfterEffectsProjectFileJobData =
    IAddAfterEffectsProjectFileJobData |
    IUpdateAfterEffectsProjectFileJobData;

@Injectable({
    providedIn: 'root'
})
export class AfterEffectsProjectFilesApiService extends ApiService<AfterEffectsProjectFileModel> {
    constructor(private monitoredJobsService: MonitoredJobsService) {
        super([ApiConstants.API_GROUP_EFFECTS_PROJECT_FILES], AfterEffectsProjectFileModel);
    }

    public postJob(jobType: EAfterEffectsProjectFilesJobType, jobData: IAfterEffectsProjectFileJobData): Observable<JobModel> {
        const request = new ApiPostJobRequest(this.groups, JobModel, jobType, jobData);
        return this.apiRequestService.postJob(request)
            .pipe(
                tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
            );
    }

    public getAfterEffectsProjectFileComposition(
        afterEffectsProjectFileId: string,
        compositionId: string
    ): Observable<AfterEffectsProjectFileCompositionModel> {
        const request = new ApiDetailsRequest(
            [
                ...this.groups,
                afterEffectsProjectFileId,
                ApiConstants.API_METHOD_COMPOSITIONS],
            AfterEffectsProjectFileCompositionModel,
            compositionId);

        return this.apiRequestService.findOne(request);
    }

    public getAfterEffectsProjectFileCompositions(afterEffectsProjectFileId: string): Observable<AfterEffectsProjectFileCompositionModel[]> {
        const request = new ApiPagedRequest(
            [
                ...this.groups,
                afterEffectsProjectFileId,
                ApiConstants.API_METHOD_COMPOSITIONS
            ], AfterEffectsProjectFileCompositionModel);

        return this.apiRequestService.findAll(request);
    }

    public getAfterEffectsProjectFileCompositionLayers(
        afterEffectsProjectFileId: string,
        afterEffectsProjectFileCompositionId: string,
        limit?: number,
        offset?: number,
        tableSortOptions: TableSortOptions = new TableSortOptions(),
        cursor?: NewCursor
    ): Observable<ARPagedResponseDataModel<AfterEffectsProjectFileCompositionLayerModel>> {
        const request = new ApiPagedRequest(
            [
                ...this.groups,
                afterEffectsProjectFileId,
                ApiConstants.API_METHOD_COMPOSITIONS,
                afterEffectsProjectFileCompositionId,
                ApiConstants.API_METHOD_LAYERS
            ], AfterEffectsProjectFileCompositionLayerModel, limit, offset, tableSortOptions, cursor);

        return this.apiRequestService.find(request);
    }
}
