import {Component, OnDestroy, OnInit} from '@angular/core';
import {ETemplateType, TemplateSizeModel} from './template-size.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../../../../classes/validators/rl-validators.constant';
import {map, pairwise, startWith, takeUntil} from 'rxjs/operators';
import {TemplateDataService} from '../template.data-service';
import {Subject} from 'rxjs';
import {EPublicationType} from '../publication-type.enum';
import {TemplatePresetModel} from '../template-preset/template-preset.model';
import {TemplateMarginsModel} from '../../../../../models/api/template-margins.model';

@Component({
    selector: 'rl-template-size',
    templateUrl: 'template-size.component.html',
    styleUrls: ['template-size.component.scss']
})
export class TemplateSizeComponent implements OnInit, OnDestroy {
    public publicationType: EPublicationType;

    public formGroup: UntypedFormGroup;
    public EPublicationType = EPublicationType;
    public ETemplateType = ETemplateType;
    public templatePreset: TemplatePresetModel;
    public allMarginsTheSame = false;

    private onDestroySubject = new Subject<void>();

    constructor(private templateDataService: TemplateDataService) {}

    public ngOnInit(): void {
        this.templateDataService.publicationType$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((publicationType) => {
            this.publicationType = publicationType;
            this.initForm();
        });

        this.templateDataService.templateSize$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((templateSize) => {
            this.formGroup.patchValue(templateSize, {emitEvent: false});
        });

        this.templateDataService.templatePreset$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((templatePreset: TemplatePresetModel) => {
            this.templatePreset = templatePreset;
        });

        this.formGroup.valueChanges.pipe(
            startWith(this.formGroup.value),
            pairwise(),
            map(([previousValue, newValue]) => {
                this.allMarginsTheSame = this.formGroup.value.margins.allMarginsTheSame;

                if (this.allMarginsTheSame) {
                    const margin = this.getMargin(previousValue.margins, newValue.margins);
                    this.formGroup.get('margins.marginTop').setValue(margin, {emitEvent: false});
                    this.formGroup.get('margins.marginBottom').setValue(margin, {emitEvent: false});
                    this.formGroup.get('margins.marginEnd').setValue(margin, {emitEvent: false});
                    this.formGroup.get('margins.marginStart').setValue(margin, {emitEvent: false});
                }

                return TemplateSizeModel.fromFormValues(this.formGroup.value);
            }),
            takeUntil(this.onDestroySubject)
        ).subscribe((newTemplateSize) => this.templateDataService.setTemplateSize(newTemplateSize));
    }

    /**
     * Checks which margin has been changed, and sends back the updated margin. By default it gets marginTop.
     */
    public getMargin(previousMargins: TemplateMarginsModel, newMargins: TemplateMarginsModel): number {
        let margin = newMargins.marginTop;

        if (previousMargins.marginBottom !== newMargins.marginBottom) {
            margin = newMargins.marginBottom;
        }
        if (previousMargins.marginEnd !== newMargins.marginEnd) {
            margin = newMargins.marginEnd;
        }
        if (previousMargins.marginStart !== newMargins.marginStart) {
            margin = newMargins.marginStart;
        }

        return margin;
    }

    public initForm(): void {
        switch (this.publicationType) {
            case EPublicationType.PRINT_MAGAZINE:
                this.formGroup = new UntypedFormGroup({
                    width: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO),
                    height: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO),
                    margins: new UntypedFormGroup({
                        allMarginsTheSame: new UntypedFormControl(this.allMarginsTheSame),
                        marginTop: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                        marginBottom: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                        marginEnd: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                        marginStart: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                    }),
                    templateType: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
                });
                break;
            case EPublicationType.POS:
            case EPublicationType.WEB:
                this.formGroup = new UntypedFormGroup({
                    width: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO),
                    height: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO),
                    margins: new UntypedFormGroup({
                        allMarginsTheSame: new UntypedFormControl(this.allMarginsTheSame),
                        marginTop: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                        marginBottom: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                        marginEnd: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                        marginStart: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                    }),
                });
                break;
        }
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
