import {autoserializeAs} from 'cerialize';
import {MasterPageModel} from './master-page.model';
import {FormatRulesetModel} from '../../modules/format-rulesets/models/api/format-ruleset.model';
import {DesignLibraryModel} from '../../modules/format-rulesets/models/api/design-library.model';
import {VariantModel} from './variant.model';

export class TemplateVariantPresetModel {
    @autoserializeAs(VariantModel) public variant: VariantModel;
    @autoserializeAs(MasterPageModel) public masterPage: MasterPageModel;
    @autoserializeAs(DesignLibraryModel) public indesignLibrary: DesignLibraryModel;
    @autoserializeAs(FormatRulesetModel, 'indesignRuleSet') public formatRuleset: FormatRulesetModel;
}
