// Modules
import {NgModule} from '@angular/core';
import {MediaCardViewComponent, NUCPipesModule, RDModule} from '@relayter/rubber-duck';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../pipes/pipes.module';

// Form components
/* eslint-disable max-len */
import {AssetAssetsFormComponent} from './asset-edit-form/asset-assets-form/asset-assets-form.component';
import {AddPageFormComponent} from './add-page-form/add-page-form.component';
import {AssetEditFormComponent} from './asset-edit-form/asset-edit-form.component';
import {WorkflowAutomationFormComponent} from './workflow-automation-form/workflow-automation-form.component';
import {
    WorkflowAutomationRuleFormComponent
} from './workflow-automation-rule-form/workflow-automation-rule-form.component';
import {
    WorkflowConfigurationStepFormComponent
} from './workflow-configuration-step-form/workflow-configuration-step-form.component';
import {RuleConditionsFormComponent} from './rule-conditions-form/rule-conditions-form.component';
import {ConditionFormComponent} from './conditions-form/condition-form.component';
import {AssetFormComponent} from './asset-form/asset-form.component';
import {CampaignFormComponent} from './campaign-form/campaign-form.component';
import {
    CampaignItemModalFormComponent
} from './campaign-item-form/campaign-item-form/campaign-item-modal-form.component';
import {
    CampaignItemInlineFormComponent
} from './campaign-item-form/campaign-item-inline-form/campaign-item-inline-form.component';
import {ComponentsModule} from '../components/components.module';
import {ChangePasswordFormComponent} from './change-password-form/change-password-form.component';
import {DataFieldFormComponent} from './data-field-form/data-field-form.component';
import {
    DataFieldsInformationFormComponent
} from './data-fields-information-form-component/data-fields-information-form.component';
import {ImportDataFormComponent} from './import-data-form/import-data-form.component';
import {InviteUserFormComponent} from './invite-user-form/invite-user-form.component';
import {IndesignLibraryFormComponent} from './library-form/indesign-library-form/indesign-library-form.component';
import {SVGLibraryFormComponent} from './library-form/svg-library-form/svg-library-form.component';
import {MasterPageFormComponent} from './master-page-form/master-page-form.component';
import {NoteExportFormComponent} from './note-export-form/note-export-form.component';
import {PackageExportFormComponent} from './package-export-form/package-export-form.component';
import {PackageFormComponent} from './package-form/package-form.component';
import {PackageRuleFormComponent} from './package-rule-form/package-rule-form.component';
import {
    PackageRuleInformationFormComponent
} from './package-rule-form/package-rule-information-form/package-rule-information-form.component';
import {
    PackageRuleMaterialDataFormComponent
} from './package-rule-form/package-rule-material-data-form/package-rule-material-data-form.component';
import {PackageSetupFormComponent} from './package-setup-form/package-setup-form.component';
import {
    PackageSetupInformationFormComponent
} from './package-setup-form/package-setup-information-form/package-setup-information-form.component';
import {
    PackageSetupPackageDataFormComponent
} from './package-setup-form/package-setup-package-data-form/package-setup-package-data-form.component';
import {
    PackageSetupExportSettingsFormComponent
} from './package-setup-form/package-setup-export-settings-form/package-setup-export-settings-form.component';
import {
    PackageSetupPackageDataItemFormComponent
} from './package-setup-form/package-setup-package-data-form/package-setup-package-data-item-form/package-setup-package-data-item-form.component';
import {PackagesFormComponent} from './packages-form/packages-form.component';
import {ProductFormComponent} from './product-form/product-form.component';
import {ProductAssetsFormComponent} from './product-form/product-assets-form/product-assets-form.component';
import {PublicationFormComponent} from './publication-form/publication-form.component';
import {RoleNameFormComponent} from './role-name-form/role-name-form.component';
import {TransferOwnershipFormComponent} from './transfer-ownership-form/transfer-ownership-form.component';
import {
    CampaignItemProductsFormComponent
} from './campaign-item-form/campaign-item-products-form/campaign-item-products-form.component';
import {
    CampaignItemAssetsFormComponent
} from './campaign-item-form/campaign-item-assets-form/campaign-item-assets-form.component';
import {
    WorkflowConfigurationTransitionFormComponent
} from './workflow-configuration-transition-form/workflow-configuration-transition-form.component';
import {WorkflowConfigurationFormComponent} from './workflow-configuration-form/workflow-configuration-form.component';
import {
    WorkflowConfigurationActionFormComponent
} from './workflow-configuration-action-form/workflow-configuration-action-form.component';
import {CommonModule} from '@angular/common';
import {
    WorkflowConfigurationComponentFormComponent
} from './workflow-configuration-component-form/workflow-configuration-component-form.component';
import {
    WorkflowIdentifierSettingFragmentFormComponent
} from './workflow-configuration-form/workflow-identifier-setting-fragment-form/workflow-identifier-setting-fragment-form.component';
import {
    WorkflowConfigurationActionNotificationReceiverFormComponent
} from './workflow-configuration-action-form/workflow-configuration-action-notification-receiver-form/workflow-configuration-action-notification-receiver-form.component';
import {
    WorkflowConfigurationActionNotificationFormComponent
} from './workflow-configuration-action-form/workflow-configuration-action-notification-form/workflow-configuration-action-notification-form.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {
    WorkflowConfigurationFilterFormComponent
} from './workflow-configuration-filter-form/workflow-configuration-filter-form.component';
import {
    ProductAssetExportSetupFormComponent
} from './product-asset-export-setup-form/product-asset-export-setup-form.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
    ProductAssetExportFormComponent
} from './product-form/product-asset-export-form/product-asset-export-form.component';
import {VideoTemplateFormComponent} from './video-template-form/video-template-form.component';
/* eslint-enable max-len */

const componentList = [
    AddPageFormComponent,
    AssetAssetsFormComponent,
    AssetEditFormComponent,
    AssetFormComponent,
    CampaignFormComponent,
    CampaignItemModalFormComponent,
    CampaignItemInlineFormComponent,
    CampaignItemProductsFormComponent,
    CampaignItemAssetsFormComponent,
    ChangePasswordFormComponent,
    ConditionFormComponent,
    DataFieldFormComponent,
    DataFieldsInformationFormComponent,
    ImportDataFormComponent,
    InviteUserFormComponent,
    IndesignLibraryFormComponent,
    SVGLibraryFormComponent,
    MasterPageFormComponent,
    NoteExportFormComponent,
    PackageExportFormComponent,
    PackageFormComponent,
    PackagesFormComponent,
    PackageRuleFormComponent,
    PackageRuleInformationFormComponent,
    PackageRuleMaterialDataFormComponent,
    PackageSetupFormComponent,
    PackageSetupInformationFormComponent,
    PackageSetupPackageDataFormComponent,
    PackageSetupExportSettingsFormComponent,
    PackageSetupPackageDataItemFormComponent,
    ProductAssetExportFormComponent,
    ProductAssetsFormComponent,
    ProductFormComponent,
    PublicationFormComponent,
    RoleNameFormComponent,
    RuleConditionsFormComponent,
    TransferOwnershipFormComponent,
    WorkflowAutomationFormComponent,
    WorkflowAutomationRuleFormComponent,
    WorkflowConfigurationStepFormComponent,
    WorkflowConfigurationComponentFormComponent,
    WorkflowConfigurationTransitionFormComponent,
    WorkflowConfigurationFormComponent,
    WorkflowConfigurationActionFormComponent,
    WorkflowIdentifierSettingFragmentFormComponent,
    WorkflowConfigurationActionNotificationFormComponent,
    WorkflowConfigurationActionNotificationReceiverFormComponent,
    WorkflowConfigurationFilterFormComponent,
    ProductAssetExportSetupFormComponent,
    VideoTemplateFormComponent
];

@NgModule({
    imports: [
        ReactiveFormsModule,
        FormsModule,
        RDModule,
        PipesModule,
        NUCPipesModule,
        ComponentsModule,
        CommonModule,
        OverlayModule,
        MediaCardViewComponent,
        DragDropModule
    ],
    declarations: componentList,
    exports: componentList,
    providers: []
})

export class FormComponentsModule {
}
