import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {RLValidatorConstants} from '../../../../classes/validators/rl-validators.constant';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA,
    RDModule
} from '@relayter/rubber-duck';
import {
    ConnectionEventBody,
    ConnectionEventModel,
    ConnectionEventPatchBody,
    EWebhookEvent
} from '../../../../models/api/connection.model';
import {distinctUntilChanged, finalize, startWith, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ConnectionService} from '../../../../api/services/connection.service';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {Toaster} from '../../../../classes/toaster.class';

export interface IConsumerWebhookEventFormComponentData {
    connectionId: string;
    eventData?: ConnectionEventModel;
}

@Component({
    selector: 'consumer-webhook-event-form',
    templateUrl: './consumer-webhook-event-form.component.html',
    styleUrls: ['./consumer-webhook-event-form.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, RDModule]
})
export class ConsumerWebhookEventFormComponent implements OnInit, OnDestroy {
    public events = Object.values(EWebhookEvent).map(event => new DropdownItem(event, event));
    public form: FormGroup;

    private saveButton: ButtonConfig;
    private cancelButton: ButtonConfig;
    private onDestroySubject = new Subject<void>();
    private event: ConnectionEventModel;

    constructor(private fb: FormBuilder,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IConsumerWebhookEventFormComponentData,
                private fullModalService: FullModalService,
                private connectionsService: ConnectionService) {
        this.event = this.modalData.eventData;
    }

    public ngOnInit(): void {
        this.initButtons();
        this.setupFormGroup();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initButtons(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, this.event?._id ? 'Save' : 'Create');
        this.cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        this.fullModalService.setConfirmClose(true);

        const cancel = new FullModalActionModel(this.cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false));

        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.saveEvent();
        });

        this.fullModalService.setModalActions([cancel, save]);
    }

    private setupFormGroup(): void {
        const currentEvent = this.events.find(event =>
            event.getValue() === this.modalData.eventData?.event);
        this.form = new FormGroup({
            event: new FormControl(currentEvent, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            endpoint: new FormControl(this.modalData.eventData?.endpoint, RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
        });

        this.form.statusChanges.pipe(
            distinctUntilChanged(),
            startWith(false),
            takeUntil(this.onDestroySubject)
        ).subscribe((status) => {
            this.saveButton.disabled = status !== 'VALID'; // disable the button based on the form status
        });
    }

    private saveEvent(): void {
        if (this.event?._id) {
            const eventValue = this.form.controls['event'].dirty ? this.form.value.event.getValue() : null;
            const endpointValue = this.form.controls['endpoint'].dirty ? this.form.value.endpoint : null;

            const event = new ConnectionEventPatchBody(eventValue, endpointValue);
            this.connectionsService.updateEvent(this.modalData.connectionId, this.modalData.eventData._id, event)
                .pipe(finalize(() => this.saveButton.loading = false), takeUntil(this.onDestroySubject))
                .subscribe({
                    next: (result) => this.fullModalService.close(result),
                    error: (error) => Toaster.handleApiError(error)
                });
            // save dataMappings
        } else { // create mode
            const event = new ConnectionEventBody(this.form.value.event.getValue(), this.form.value.endpoint);
            this.connectionsService.createEvent(this.modalData.connectionId, event)
                .pipe(finalize(() => this.saveButton.loading = false), takeUntil(this.onDestroySubject))
                .subscribe({
                    next: (result) => this.fullModalService.close(result),
                    error: (error) => Toaster.handleApiError(error)
                });
        }
    }
}
