<form [formGroup]="formGroup">
    <nuc-label>Content group name</nuc-label>
    <nuc-input formControlName="name" placeholder="Provide a name for the content group"></nuc-input>
</form>

<div class="table-header">
    <p class="section-header">Add users</p>
    <p>Add one or multiple users to the new content group</p>
</div>

<div *ngIf="(permissions.GET_USERS | userIsAllowedTo); else noPermissionEmptyState" class="group-form-container">
    <nuc-table [tableDataProvider]="usersDataProvider"
               [tableSortOptions]="usersDataProvider.sortOptions"
               [emptyStateTitle]="searchValue ? 'No item matches your search criteria.' : null"
               [columns]="columns"
               [loading]="usersDataProvider.loading"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (tableSortOptionsChange)="setPageIndex()"
               [selectionMode]="ESelectionMode.MULTI">
        <ng-container button-bar-left>
            <nuc-input class="search"
                       [(data)]="searchValue"
                       prefix="nucicon_search"
                       (debounceValueChanged)="setPageIndex()"
                       placeholder="Search users">
            </nuc-input>
        </ng-container>
        <ng-container button-bar-right>
            <paginator [viewId]="tableId"
                       [disableNextPage]="usersDataProvider.disableNextPage"
                       [loading]="usersDataProvider.loading">
            </paginator>
        </ng-container>
    </nuc-table>
</div>

<ng-template #noPermissionEmptyState>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission.svg"
                               title="No permission"
                               subtitle="You do not have the permission to view users.">
    </nuc-empty-state-component>
</ng-template>
