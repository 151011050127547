<div class="container">
    <ng-container *ngFor="let group of listGroups">
        <p class="title" *ngIf="group.title">{{ group.title }}</p>
        <ul class="list">
            <li *ngFor="let item of group.list">{{ item }}</li>
        </ul>
    </ng-container>
</div>

<form [formGroup]="form" *ngIf="PERMISSIONS.POST_TEMPLATE_JOBS | userIsAllowedTo">
    <p class="body-strong grey">To delete this template, migrate the campaigns and/or workflow
        automations to use another template.
        <nuc-icon-button *ngIf="isPrintTemplate" icon="nucicon_sub_information"
                         nucTooltipPosition="right"
                         nucTooltip="You can only choose a template with the same type"></nuc-icon-button>
    </p>
    <nuc-dropdown formControlName="templateToMigrate"
                  [limit]="pageSize"
                  [items]="templates"
                  [total]="totalTemplates"
                  (requestData)="getTemplates($event)"
                  placeholder="Select a template"
                  required>
    </nuc-dropdown>
</form>
