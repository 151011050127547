import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA,
    RDModule
} from '@relayter/rubber-duck';
import {ConnectionService} from '../../api/services/connection.service';
import {ConnectionApiModel, ConnectionModel, EConnectionType} from '../../models/api/connection.model';
import {Toaster} from '../../classes/toaster.class';
import {distinctUntilChanged, finalize, map} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgIf, NgSwitch, NgSwitchCase} from '@angular/common';

export interface IConnectionFormData {
    connection?: ConnectionModel;
}

@Component({
    selector: 'connection-form',
    templateUrl: 'connection-form.component.html',
    styleUrls: ['connection-form.component.scss'],
    providers: [],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        RDModule,
        FormsModule,
        NgSwitch,
        NgSwitchCase,
        NgIf
    ]
})
export class ConnectionFormComponent implements OnInit {
    private destroyRef: DestroyRef = inject(DestroyRef);
    public connection: ConnectionModel;
    public readonly EConnectionType = EConnectionType;
    public connections: DropdownItem<string>[] = [
        new DropdownItem<string>('MediaValet', EConnectionType.MEDIA_VALET),
        new DropdownItem<string>('Webhook Consumer', EConnectionType.WEBHOOK_CONSUMER),
        new DropdownItem<string>('Superunie Adam', EConnectionType.SUPERUNIE_ADAM),
        new DropdownItem<string>('Webhook Producer', EConnectionType.WEBHOOK_PRODUCER)
    ];
    private saveButton: ButtonConfig;
    public logo: string;

    public form = new FormGroup({
        name: new FormControl('', Validators.required),
        connectionType: new FormControl<DropdownItem<string>>(null, Validators.required),
        details: new FormGroup({})
    });

    get connectionTypeControl(): FormControl<DropdownItem<string> | null> {
        return this.form.controls.connectionType;
    }

    get detailForm(): FormGroup {
        return this.form.controls.details;
    }

    set detailForm(form: FormGroup) {
        this.form.removeControl('details');
        this.form.addControl('details', form);
    }

    constructor(private fullModalService: FullModalService,
                private connectionService: ConnectionService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IConnectionFormData) {
        this.connection = this.modalData.connection || new ConnectionModel();
    }

    public ngOnInit(): void {
        this.addButtons();
        this.listenToConnectionType();
        if (this.modalData.connection) this.patchInitialValue();
        this.saveButton.disabled = this.form.status !== 'VALID';
        this.listenToFormStatus();
    }

    public addButtons(): void {
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));
        cancelAction.observable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.fullModalService.close(null, true));
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);
        const saveAction = new FullModalActionModel(this.saveButton);
        saveAction.observable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.createOrUpdateConnection());

        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private listenToConnectionType(): void {
        this.connectionTypeControl.valueChanges
            .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
            .subscribe((value) => {
                const connectionType = value?.getValue() as EConnectionType;
                this.logo = ConnectionModel.getLogo(connectionType);
                this.manageFormControls(connectionType);
            });
    }

    private manageFormControls(connectionType: EConnectionType): void {
        switch (connectionType) {
            case EConnectionType.MEDIA_VALET:
                this.detailForm = new FormGroup({
                    clientId: new FormControl(this.connection.clientId, Validators.required),
                    clientSecret: new FormControl(this.connection.clientSecret, Validators.required),
                    subscriptionKey: new FormControl(this.connection.subscriptionKey, Validators.required)
                });
                break;
            case EConnectionType.WEBHOOK_CONSUMER:
                this.detailForm = new FormGroup({});
                break;
            case EConnectionType.SUPERUNIE_ADAM:
                this.detailForm.addControl('apiKey', new FormControl(this.connection.apiKey, Validators.required));
                const items = (this.connection.globalLocationNumbers || []).map((v: string) => new DropdownItem(v, v));
                this.detailForm.addControl('globalLocationNumbers', new FormControl(items));
                break;
            case EConnectionType.WEBHOOK_PRODUCER:
                this.detailForm.addControl('authenticationToken', new FormControl(this.connection.authenticationToken, Validators.required));
                this.detailForm.addControl('secret', new FormControl(this.connection.secret));
                break;
            default:
                this.detailForm = new FormGroup({});
                Toaster.error(`Currently we don't support connection type: ${connectionType}`);
        }
    }

    private patchInitialValue(): void {
        const selectedConnectionType = this.connections.find((item) => item.getValue() === this.modalData.connection.connectionType);
        this.form.controls.connectionType.patchValue(selectedConnectionType);
        if (selectedConnectionType) this.connectionTypeControl.disable({onlySelf: true});
        this.form.patchValue({
            connectionType: selectedConnectionType,
            name: this.modalData.connection.name
        });
    }

    private listenToFormStatus(): void {
        this.form.statusChanges
            .pipe(distinctUntilChanged(), map((status) => status === 'VALID'), takeUntilDestroyed(this.destroyRef))
            .subscribe((isValid) => this.saveButton.disabled = !isValid);
    }

    public createOrUpdateConnection(): void {
        const name = this.form.value.name;
        const connectionType = this.form.getRawValue().connectionType.getValue() as EConnectionType;

        const body = new ConnectionApiModel(name, connectionType);

        switch (connectionType) {
            case EConnectionType.MEDIA_VALET:
                body.clientId = this.detailForm.value.clientId;
                body.clientSecret = this.detailForm.value.clientSecret;
                body.subscriptionKey = this.detailForm.value.subscriptionKey;
                break;
            case EConnectionType.WEBHOOK_CONSUMER:
                break;
            case EConnectionType.SUPERUNIE_ADAM:
                body.apiKey = this.detailForm.value.apiKey;
                body.globalLocationNumbers = (this.detailForm.value.globalLocationNumbers || [])
                    .map((GLN: DropdownItem<string>) => GLN.getValue());
                break;
            case EConnectionType.WEBHOOK_PRODUCER:
                body.authenticationToken = this.detailForm.value.authenticationToken;
                body.secret = this.detailForm.value.secret;
                break;
        }

        const observable = this.connection._id
            ? this.connectionService.editConnection(this.modalData.connection._id, body)
            : this.connectionService.createConnection(body);

        observable
            .pipe(
                finalize(() => this.saveButton.loading = false),
                takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result: ConnectionModel) => {
                    const operation = this.connection._id ? 'updated' : 'created';
                    this.fullModalService.close(result);
                    Toaster.success(`Connection ${operation} successfully`);
                },
                error: Toaster.handleApiError
            });
    }
}
