import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {TemplateModel} from '../../models/api/template.model';
import {JobModel} from '../../models/api/job.model';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-jobs.service';

interface ITemplateJobData {
    templateId: string;
    templateIdToMigrate: string;
}

export enum ETemplateJobType {
    DELETE_TEMPLATE_JOB = 'DELETE_TEMPLATE_JOB'
}

@Injectable({providedIn: 'root'})
export class TemplatesApiService extends ApiService<TemplateModel> {

    constructor(private monitoredJobsService: MonitoredJobsService) {
        super([ApiConstants.API_GROUP_TEMPLATES], TemplateModel);
    }

    public postJob(jobType: ETemplateJobType, jobData: ITemplateJobData): Observable<JobModel> {
        const request = new ApiPostJobRequest(this.groups, JobModel, jobType, jobData);

        return this.apiRequestService.postJob(request)
            .pipe(
                tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
            );
    }
}
