<div>
    <div class="connection-details-header">
        <h1>Connection details</h1>
        <nuc-button-secondary
            *ngIf="permissions.PATCH_CONNECTION | userIsAllowedTo"
            (click)="editConnection()"
            [disabled]="loading"
            text="Edit">
        </nuc-button-secondary>
    </div>
    <div class="connection-details" *ngIf="connection">
        <div class="left">
            <img class="logo" [src]="logo">
        </div>
        <div class="properties-container">
            <div class="properties">
                <div class="field">
                    <p class="body-strong">Name</p>
                    <p>{{ connection.name }}</p>
                </div>
                <div class="field">
                    <p class="body-strong">Authentication Token</p>
                    <key-viewer [key]="connection.authenticationToken" [disabled]="loading">
                    </key-viewer>
                </div>
                <div class="field">
                    <p class="body-strong">Secret</p>
                    <key-viewer [key]="connection.secret" [disabled]="loading">
                    </key-viewer>
                </div>
            </div>
        </div>
    </div>
    <div class="endpoints">
        <div class="header-row">
            <h1>Endpoints</h1>
            <nuc-button-bar>
                @if (permissions.POST_WEBHOOK_ENDPOINT | userIsAllowedTo) {
                    <nuc-button-secondary text="Add endpoint" (click)="addEndpoint()"></nuc-button-secondary>
                }
            </nuc-button-bar>
        </div>
        @for (endpoint of connection?.endpoints; track endpoint._id) {
            <div class="endpoint-card">
                <div class="property model">
                    <h3>Model</h3>
                    <span>{{endpoint.collectionName | collectionDisplayName}}</span>
                </div>
                <div class="property url">
                    <h3>Url</h3>
                    <span>https://api.relayter.com/api/webhook-endpoint/{{endpoint._id}}</span>
                </div>
                <div class="property action">
                    <h3>Action</h3>
                    <span>{{endpoint.action}}</span>
                </div>
                <div class="property mapping">
                    <h3>Mapped fields</h3>
                    <span>{{endpoint.mappings.length}}</span>
                </div>
                <div class="actions">
                    <nuc-button-secondary icon="nucicon_edit" nucTooltip="Edit endpoint"/>
                    <nuc-button-secondary icon="nucicon_trash_line" nucTooltip="Delete endpoint"/>
                </div>
            </div>
        }
    </div>
</div>
